import Api from 'api/api';

class ProductApi {
    postPayment(form, jwt) {
        return Api.post('/payment/create', form, config(jwt));
    }

    postPayseraCallback(queryParams) {
        return Api.post('/public/payment/front/callback' + queryParams);
    }

    getPaginatedUserPaymentList(pagination, jwt) {
        return Api.get('/payment/user/list?' + pagination, config(jwt));
    }

    getPaginatedAdminPaymentList(pagination, jwt) {
        return Api.get('/payment/admin/list?' + pagination, config(jwt));
    }
}

let config = (jwt) => {
    return {
        headers: {
            Authorization: 'Bearer ' + jwt,
        }
    }
}

export default new ProductApi();
