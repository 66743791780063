import Api from 'api/api';

class PublicUserApi {
    postResetPassword(body) {
        return Api.post('/public/user/password/reset/request', body);
    }

    validateJwt(jwt) {
        return Api.get('/public/user/validate/jwt', config(jwt));
    }
}

let config = (jwt) => {
    return {
        headers: {
            Authorization: 'Bearer ' + jwt,
        }
    }
}

export default new PublicUserApi();
