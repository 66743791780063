import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'i18n/i18n';

@translate()
class ProductDurationCell extends Component {
    render() {
        const { t, row } = this.props;
        const { isCreditSubscription, subscriptionPeriod } = row;

        if (isCreditSubscription) {
            return (
                <span className="bolder">{subscriptionPeriod} {t('COMMON.TIMES_SHORT')}</span>
            );
        } else {
            return (
                <span className="bolder">{subscriptionPeriod} {t('COMMON.DAYS_SHORT')}</span>
            );
        }


    }
}

ProductDurationCell.propTypes = {
    row: PropTypes.any,
}

export default ProductDurationCell;
