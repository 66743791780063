import { observable } from 'mobx';
import Paginated from 'store/Utils/Paginated';
import ProductApi from 'api/ProductApi';

class ProductAdminListStore extends Paginated {
    @observable getDataPromise;

    constructor(rootStore) {
        super(rootStore);
        this.getDataPromise = getPaginatedProductAdminListPromise;
    }
}

const getPaginatedProductAdminListPromise = (pagination, jwt) => {
    return new Promise((resolve, reject) => {
        ProductApi.getPaginatedProductAdminList(pagination, jwt)
            .then(({ data }) => resolve(data))
            .catch(() => reject());
    })
}

export default ProductAdminListStore;
