import React, { Component } from 'react';
import LandingTimetable from 'app/modules/landing/components/LandingTimetable';
import { Alert, Button, Col, Container, Form, FormControl, Row, Spinner } from 'react-bootstrap';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import paysera from 'assets/img/paysera.png';
import FormValidators from 'app/components/validators/FormValidators';

@translate()
@withRouter
@inject('store')
@observer
class PaymentForm extends Component {
    componentDidMount() {
        const { match: { params: { id } } } = this.props;
        this.props.store['payment'].form.getProduct(id)
    }

    valueChange(fieldName, value) {
        this.props.store['payment'].form.setFormValue(fieldName, value);
    }

    isReady() {
        const { isCompanyInvoice, companyName, companyCode, companyAddress } = this.props.store['payment'].form.form;
        const { empty } = new FormValidators();

        return !isCompanyInvoice || (!empty(companyName) && !empty(companyCode) && !empty(companyAddress));
    }

    render() {
        const { t } = this.props;
        const form = this.props.store['payment'].form;
        const user = this.props.store.user;
        const { product, loading, errorEnabled, errorMessage } = form;

        const { isCompanyInvoice, companyName, companyCode, companyAddress } = this.props.store['payment'].form.form;
        const valueChange = fieldName => e => this.valueChange(fieldName, e.target.value)

        return (
            <div className="position-relative overflow-hidden landing">
                <LandingTimetable />


                <Col xs={11} md={10} lg={8} xl={6} className="mx-auto tab-box pb-0">
                    <div className="user-tab">
                        {loading && !product &&
                            <div className="spinner-box">
                                <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
                            </div>
                        }


                        {!loading && !product && errorEnabled &&
                            <div className="spinner-box">
                                <Alert variant="danger" className="text-center">{t(errorMessage)}</Alert>
                            </div>
                        }

                        {product &&
                            <div className="pt-3">
                                <div>
                                    <Col md={12} className="text-center mb-3">
                                        <span className="payment-title">{t('COMMON.NEW_PAYMENT')}</span>
                                    </Col>

                                    <Col md={12}>
                                        <Row>
                                            <Col md={6} sm={12} className="payment-item pt-2 pb-2">
                                                <Form>
                                                    <Form.Group>
                                                        <Form.Label>{t('COMMON.FIRST_NAME')}</Form.Label>
                                                        <Form.Control value={user.firstName} disabled />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Label>{t('COMMON.LAST_NAME')}</Form.Label>
                                                        <Form.Control value={user.lastName} disabled />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Label>{t('COMMON.EMAIL')}</Form.Label>
                                                        <Form.Control value={user.email} disabled />
                                                        <Form.Text id="passwordHelpBlock" muted>
                                                            {t('COMMON.PASSWORD_CHANGE_INFO')}
                                                        </Form.Text>
                                                    </Form.Group>

                                                    <Row>
                                                        <Col>
                                                            <div className="custom-control custom-checkbox pb-2">
                                                                <input type="checkbox"
                                                                       className="custom-control-input"
                                                                       id="isPersonalInvoice"
                                                                       checked={!isCompanyInvoice}
                                                                       onChange={() =>
                                                                           this.valueChange('isCompanyInvoice',
                                                                               false)
                                                                       }
                                                                />
                                                                <label className={`custom-control-label pointer
                                                                       ${!isCompanyInvoice && 'invoice-chosen'}`}
                                                                       htmlFor="isPersonalInvoice">
                                                                    {t('COMMON.PERSONAL_INVOICE')}
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="custom-control custom-checkbox pb-2">
                                                                <input type="checkbox"
                                                                       className="custom-control-input"
                                                                       id="isCompanyInvoice"
                                                                       checked={isCompanyInvoice}
                                                                       onChange={() =>
                                                                           this.valueChange('isCompanyInvoice',
                                                                               true)
                                                                       }
                                                                />
                                                                <label className={`custom-control-label pointer
                                                                       ${isCompanyInvoice && 'invoice-chosen'}`}
                                                                       htmlFor="isCompanyInvoice">
                                                                    {t('COMMON.COMPANY_INVOICE')}
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>



                                                    {isCompanyInvoice &&
                                                        <div className="pt-2 pb-2">
                                                            <Form.Group>
                                                                <Form.Label>{t('COMMON.COMPANY_NAME')}</Form.Label>
                                                                <FormControl value={companyName}
                                                                             onChange={
                                                                                 valueChange('companyName')
                                                                             } />
                                                            </Form.Group>

                                                            <Form.Group>
                                                                <Form.Label>{t('COMMON.COMPANY_CODE')}</Form.Label>
                                                                <FormControl value={companyCode}
                                                                             onChange={
                                                                                 valueChange('companyCode')
                                                                             } />
                                                            </Form.Group>

                                                            <Form.Group>
                                                                <Form.Label>{t('COMMON.COMPANY_ADDRESS')}</Form.Label>
                                                                <FormControl value={companyAddress}
                                                                             onChange={
                                                                                 valueChange('companyAddress')
                                                                             } />
                                                            </Form.Group>
                                                        </div>
                                                    }

                                                </Form>
                                            </Col>
                                            <Col md={6} sm={12} className="payment-item pt-2 payment-summary-box">
                                                <div className="payment-summary">
                                                    <div className="payment-item-title pb-4">
                                                        {product.title}
                                                    </div>

                                                    <div className="payment-item-desc pb-4">
                                                        <span className="payment-desc-text">
                                                            {product.description}
                                                        </span>
                                                    </div>

                                                    <div className="payment-item-price">
                                                        <span className="payment-total">
                                                            {t('COMMON.TOTAL_PRICE')}:
                                                        </span>
                                                        <span className="payment-price">
                                                            {product.price.toFixed(2)} €
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <hr width="100%" className="mt-1" />

                                    <div className="mb-4 mt-4 text-center">
                                        <Container>
                                            <h5>
                                                {t('COMMON.PAYMENT_TYPES')}
                                            </h5>
                                            <div className="payment-type-info">
                                                {t('COMMON.PAYMENT_SECURITY')}
                                            </div>
                                            <img
                                                src={paysera}
                                                className="paysera"
                                                alt="paysera"
                                            />
                                            <div className="pay-btn">
                                                <Button variant="primary" size="md"
                                                        disabled={loading || !this.isReady()}
                                                        onClick={() => form.pay()}>
                                                    {t('COMMON.CONTINUE_TO_PAYMENT')}
                                                    {loading &&
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="custom"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    }
                                                </Button>
                                            </div>

                                            {!loading && errorEnabled &&
                                                <div className="text-center justify-content-center flex-centered">
                                                    <Alert variant="danger" className="payment-error-block">
                                                        {t(errorMessage)}
                                                    </Alert>
                                                </div>
                                            }

                                            <div className="payment-next-step">
                                                {t('COMMON.NEXT_PAYMENT_STEP')}
                                            </div>
                                        </Container>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Col>
            </div>
        )
    }
}

export default PaymentForm;
