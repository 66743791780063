import i18n from 'i18next';
import lt from './locales/lt';
import { withTranslation } from 'react-i18next';

export const translate = withTranslation;

i18n.init({
    debug: process.env.REACT_APP_ENV === 'development',

    lng: 'lt',
    resources: {
        'lt': {
            'translation': lt
        }
    },
    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    }
});

export default i18n;
