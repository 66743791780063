import React, { Component } from 'react';
import { Alert, Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import ImageUploader from 'react-images-upload';
import ModalConfirmActionBtn from 'app/components/modals/ModalConfirmActionBtn';

@translate()
@inject('store')
@observer
class TimetableForm extends Component {
    onDrop(base64) {
        this.props.store.timetable.setFormData(base64[0])
    }

    render() {
        const { t } = this.props;
        const { timetable } = this.props.store;
        const { loading, formData, data, successEnabled, successMessage, errorEnabled, errorMessage } = timetable;

        return (
            <Container>
                <Row>
                    <Col md={12}>
                        <ImageUploader
                            className="img-uploader"
                            withIcon={true}
                            buttonText={t('COMMON.CHOOSE_IMAGE')}
                            onChange={(file, base64) => {this.onDrop(base64)}}
                            imgExtension={['.jpg', '.png']}
                            maxFileSize={5242880}
                            withPreview
                            singleImage
                        />
                    </Col>

                    <Col md={12}>
                        {successEnabled && <Alert variant="success" className="text-center">{t(successMessage)}</Alert>}
                        {errorEnabled && <Alert variant="warning" className="text-center">{t(errorMessage)}</Alert>}
                    </Col>

                    <Col className="text-right mt-3" md={12}>
                        {data &&
                            <ModalConfirmActionBtn
                                text={t('COMMON.TURN_OFF_TIMETABLE')}
                                confirmText={t('COMMON.YES')}
                                cancelText={t('COMMON.NO')}
                                questionText={t('COMMON.REALLY_TURN_OFF_TIMETABLE')}
                                onConfirm={() => timetable.delete()}
                                loading={loading} />
                        }

                        <Button variant="primary" className="ml-2"
                                disabled={loading || !formData}
                                onClick={() => timetable.upload()}>
                            {t('COMMON.UPLOAD')}
                            {loading && <Spinner as="span" animation="border" size="custom" role="status" />}
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default TimetableForm;
