import { action, observable } from 'mobx';

class LoginModalStore {
    @observable loginModalPages = Object.freeze(
        { signIn: 'SIGN_IN', signUp: 'SIGN_UP', resetPassword: 'RESET_PASSWORD' }
        );

    @observable show = false;
    @observable page = this.loginModalPages.signIn;
    @observable form = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        healthTermsAccepted: false,
        policyTermsAccepted: false
    }

    @observable invalidFields = {};

    @observable loading = false;

    @observable errorEnabled;
    @observable errorMessage;
    @observable successEnabled;
    @observable successMessage;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    postRegistration() {
        if (!this.loading) {
            this.setLoading(true);
            this.disableAlerts();

            this.rootStore.user.postRegistration(this.form)
                .then((message) => {
                    this.enableSuccessAlert(message)
                    setTimeout(() => this.redirectToFrontPage(), 2100);
                })
                .catch((message) => {
                    this.setLoading(false);
                    this.enableErrorAlert(message)
                });
        }
    }

    @action
    postLogin() {
        if (!this.loading) {
            this.setLoading(true);
            this.disableAlerts();

            this.rootStore.user.postLogin(this.form)
                .then(() => {
                    this.redirectToFrontPage();
                })
                .catch((message) => {
                    this.setLoading(false);
                    this.enableErrorAlert(message)
                });
        }
    }

    @action
    postPasswordReset() {
        if (!this.loading) {
            this.setLoading(true);
            this.disableAlerts();

            this.rootStore.user.postPasswordReset(this.form)
                .then((message) => {
                    this.enableSuccessAlert(message);
                    setTimeout(() => this.redirectToFrontPage(), 4000);
                })
                .catch((message) => {
                    this.setLoading(false);
                    this.enableErrorAlert(message)
                });
        }
    }

    @action
    redirectToFrontPage() {
        window.location.reload();
    }

    @action
    enableSuccessAlert(message) {
        this.successMessage = message;
        this.successEnabled = true;
    }

    @action
    enableErrorAlert(message) {
        this.errorMessage = message;
        this.errorEnabled = true;
    }

    @action
    disableAlerts() {
        this.errorEnabled = false;
        this.successEnabled = false;
        this.errorMessage = null;
        this.successMessage = null;
    }

    @action
    setLoading(value) {
        this.loading = value;
    }

    @action
    setShow(value) {
        if (value) {
            this.show = value;
        } else {
            this.show = value;
            this.rootStore.modal.resetModals();
        }
    }

    @action
    resetInitialValues() {
        this.page = this.loginModalPages.signIn;
    }

    @action
    setPage(value) {
        this.page = value;
    }

    @action
    setFormValue(fieldName, value) {
        this.form[fieldName] = value;
    }

    @action
    setInvalidFields(fieldName) {
        this.invalidFields[fieldName] = true;
    }

    @action
    removeInvalidField(fieldName) {
        this.invalidFields[fieldName] = false;
    }

    @action
    resetInvalidFields() {
        this.invalidFields = {};
    }
}

export default LoginModalStore;
