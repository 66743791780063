import React, { Component, Fragment } from 'react';
import { Badge } from 'react-bootstrap';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Pagination from 'app/components/pagination/Pagination';
import PaymentViewModal from 'app/modules/admin/components/Payment/PaymentViewModal';

@translate()
@inject('store')
@observer
class PaymentAdminListTab extends Component {
    constructor() {
        super();
        this.state = { row: null, show: false }
    }

    openPayment(row) {
        this.setState({ row, show: true });
    }

    closePayment() {
        this.setState({ show: false });
    }

    render() {
        const { t } = this.props;

        const columns = [
            {
                name: t('COMMON.USER'),
                selector: (row) => {
                    return (<span>{row['user'].firstName} {row['user'].lastName}</span>)
                },
                sortable: false,
                minWidth: '200px',
            },
            {
                name: t('COMMON.ORDER_ID_SHORT'),
                selector: 'orderId',
                format: (row) => <span className="bolder">{row['orderId']}</span>,
                sortable: true,
                center: true,
                minWidth: '130px',
                maxWidth: '150px',
            },
            {
                name: t('COMMON.PAYMENT_PAID'),
                selector: 'paid',
                format: (row) => row['paid'] ? moment(row['paid']).format('MM-DD HH:mm') : null,
                sortable: true,
                center: true,
                minWidth: '140px',
                maxWidth: '160px',
            },
            {
                name: t('COMMON.CREATION_DATE'),
                selector: 'created',
                format: (row) => row['created'] ? moment(row['created']).format('MM-DD HH:mm') : null,
                sortable: true,
                center: true,
                minWidth: '140px',
                maxWidth: '160px',
            },
            {
                name: t('COMMON.STATUS'),
                selector: (row) => {
                    return row['paid'] ?
                        <Badge variant="success">{t('COMMON.PAID')}</Badge>
                        :
                        <Badge variant="secondary">{t('COMMON.PAYMENT_NOT_FINISHED')}</Badge>
                },
                center: true,
                minWidth: '100px',
                maxWidth: '130px',
                style: { fontSize: '1rem' }
            },
            {
                name: t('COMMON.TOTAL_PRICE'),
                selector: 'amount',
                format: (row) => <span className="bolder">{row.amount.toFixed(2) + ' ' + row.currency}</span>,
                sortable: true,
                right: true,
                minWidth: '80px',
                maxWidth: '80px',
            }
        ];

        const { admin } = this.props.store.payment;

        return (
            <Fragment>
                <Pagination paginatedStore={admin} columns={columns} withSearch
                            pointerOnHover
                            onRowClicked={(row) => this.openPayment(row)} />
                <PaymentViewModal
                    show={this.state.show}
                    row={this.state.row}
                    onClose={() => this.closePayment()} />
            </Fragment>
        );
    }
}

export default PaymentAdminListTab;
