import LoginModalStore from 'store/ModalStore/LoginModalStore';
import { action } from 'mobx';

class ModalStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.loginModal = new LoginModalStore(rootStore);
    }

    @action
    resetModals() {
        this.loginModal = new LoginModalStore(this.rootStore);
    }
}

export default ModalStore;
