import Api from 'api/api';

class UserApi {
    getUser(jwt) {
        return Api.get('/user/me', config(jwt));
    }

    postPasswordChange(body, jwt) {
        return Api.post('/user/password/reset', body, config(jwt));
    }

    postEmailChange(body, jwt) {
        return Api.post('/user/email/reset', body, config(jwt));
    }

    getPaginatedAdminUserList(pagination, jwt) {
        return Api.get('/user/admin/list?' + pagination, config(jwt));
    }

    getUserById(id, jwt) {
        return Api.get('/user/admin/' + id, config(jwt));
    }

    patchUser(body, jwt) {
        return Api.patch('/user/patch/', body, config(jwt));
    }

    deleteUserById(id, jwt) {
        return Api.delete('/user/delete/' + id, config(jwt));
    }

    postUserInviteMail(id, jwt) {
        return Api.post('/user/invite/request/' + id, null, config(jwt));
    }

    postAddUser(body, jwt) {
        return Api.post('/user/add', body, config(jwt));
    }
}

let config = (jwt) => {
    return {
        headers: {
            Authorization: 'Bearer ' + jwt,
        }
    }
}

export default new UserApi();
