import React, { Component, Fragment } from 'react';
import { Navbar, Container } from 'react-bootstrap';
import logo from 'assets/img/logo.png';
import logoText from 'assets/img/logo-text.png';
import 'scss/navbar.scss'
import Button from 'react-bootstrap/Button';
import { translate } from 'i18n/i18n';
import LoginModal from 'app/components/modals/login/LoginModal';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

@withRouter
@translate()
@inject('store')
@observer
class MainNavbar extends Component {
    constructor() {
        super();
        this.state = { expand: false };
    }

    setExpand(value) {
        this.setState({ expand: value });
    }

    render() {
        const { t, history, store: { modal: { loginModal }, user } } = this.props;
        const { loggedIn, isAdmin } = user;
        const { show } = loginModal;

        const openLoginModal = () => loginModal.setShow(true);
        const logout = () => user.logout();

        return (
            <Fragment>
                <Navbar collapseOnSelect expand="sm" className="navbar"
                        onToggle={(val) => this.setExpand(val)} expanded={this.state.expand}>
                    <Container>
                        <Navbar.Brand className="brand pointer"
                                      onClick={() => {this.setExpand(false);window.location.href = '/'}}>
                            <img
                                src={logo}
                                className="d-inline-block align-top logo ml-3"
                                alt="U7M logo"
                            />
                            <img
                                src={logoText}
                                className="d-inline-block align-top logo-text"
                                alt="U7M logo"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <div className="mr-auto mt-3"></div>
                            {loggedIn ?
                                <Fragment>
                                    {isAdmin &&
                                        <Button variant="outline-primary" className="nav-btn m-2"
                                                onClick={() => {
                                                    this.setExpand(false);
                                                history.push('/admin')
                                                }}>
                                            {t('COMMON.ADMIN')}
                                        </Button>
                                    }
                                    <Button variant="outline-primary" className="nav-btn"
                                            onClick={() => {
                                                this.setExpand(false);
                                                history.push('/user')
                                            }}>
                                        {t('COMMON.MY_PAGE')}
                                    </Button>
                                    <Button variant="light" className="nav-btn logout"
                                            onClick={logout}>
                                        {t('COMMON.LOGOUT')}
                                    </Button>
                                </Fragment>
                                :
                                <Button variant="outline-primary" className="nav-btn" onClick={openLoginModal}>
                                    {t('COMMON.LOGIN')}
                                </Button>
                            }
                            <div className="mt-3"></div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <LoginModal show={show} />
            </Fragment>
        );
    }
}

export default MainNavbar;
