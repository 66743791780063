import React, { Component, Fragment } from 'react';
import 'scss/landing.scss'
import { Col, Spinner, Container, Row, Button, Alert } from 'react-bootstrap';
import LandingTimetable from 'app/modules/landing/components/LandingTimetable';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

@translate()
@withRouter
@inject('store')
@observer
class Subscription extends Component {
    componentDidMount() {
        this.props.store.product.getProducts();
    }

    render() {
        const { t, history } = this.props;
        const { products, loading } = this.props.store.product;
        const { loggedIn } = this.props.store.user;
        const { loginModal } = this.props.store.modal;
        const { isOnlineSubscriptionValid, isSubscriptionValid } = this.props.store.user;

        const openLoginModal = () => loginModal.setShow(true);

        return (
            <div className="position-relative overflow-hidden landing">
                <LandingTimetable />


                <Col sm={8} md={10} lg={10} xl={8} className="mx-auto product-container">
                    {loading ?
                        <div className="spinner-box">
                            <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
                        </div>
                        :
                        <Fragment>
                            {!loggedIn &&
                                <Alert variant="primary">
                                    {t('COMMON.SUBSCRIPTION_BUY_NOTICE')} <Alert.Link onClick={() => openLoginModal()}>
                                    {t('COMMON.SUBSCRIPTION_BUY_NOTICE_LINK')}</Alert.Link>.
                                </Alert>
                            }
                            <Container className="product-container">
                                {(isOnlineSubscriptionValid || isSubscriptionValid) &&
                                    <Alert variant="info" className="text-center">
                                        {t('COMMON.EXTEND_SUBSCRIPTION_INFO')}
                                    </Alert>
                                }
                                <Row>
                                    {products.map((product, i) => {
                                        return (
                                            <Col xs={12} md={6} lg={4} xl={4} key={i}>
                                                <div className="card mb-4 box-shadow">
                                                    <div className="card-header">
                                                        <div className="product-title">
                                                            {product.title}
                                                        </div>
                                                    </div>
                                                    <div className="card-body pb-1">
                                                        <div className="product-price">
                                                            {product.price.toFixed(2)} <span
                                                            className="text-muted"> €</span>
                                                        </div>
                                                        <div className="product-description">
                                                            {product.description}
                                                        </div>
                                                            <Button variant="primary" className="btn-block mb-3"
                                                                    disabled={!loggedIn}
                                                                    onClick={() => {
                                                                        if (loggedIn) {
                                                                            history.push(`pay/${product.id}`)
                                                                        }
                                                                    }}>
                                                                {loggedIn ? t('COMMON.BUY') :
                                                                    t('COMMON.BUY_AFTER_LOGIN')}
                                                            </Button>
                                                    </div>
                                                    {product.isOnlineSubscription &&
                                                        <div className="sub-online-item">{t('COMMON.ONLINE')}</div>}
                                                    {product.isSubscriptionFull &&
                                                        <div className="sub-gym-item">
                                                            {t('COMMON.SUBSCRIPTION_IS_FULL_TYPE')}
                                                        </div>
                                                    }
                                                    {!product.isSubscriptionFull && !product.isOnlineSubscription &&
                                                        <div className="sub-gym-item">
                                                            {t('COMMON.GYM_HALL')}
                                                        </div>
                                                    }
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Container>
                        </Fragment>
                    }
                </Col>
            </div>
        )
    }
}

export default Subscription;
