import Api from 'api/api';

class WorkoutApi {
    postWorkoutUpload(body, jwt) {
        return Api.post('/workout/upload', body, config(jwt));
    }

    patchWorkout(body, jwt) {
        return Api.patch('/workout/patch', body, config(jwt));
    }

    deleteWorkout(id, jwt) {
        return Api.delete('/workout/delete/' + id, config(jwt));
    }

    getPaginatedAdminWorkoutList(pagination, jwt) {
        return Api.get('/workout/list?' + pagination, config(jwt));
    }

    getFreeWorkoutList() {
        return Api.get('/public/workout/list/free');
    }

    getPaidWorkoutList(jwt) {
        return Api.get('/public/workout/list/paid?page=0&pageSize=200&sortBy=created&sortDir=DESC', config(jwt));
    }

    getFreeWorkoutById(id) {
        return Api.get('/public/workout/watch/' + id);
    }

    getPaidWorkoutById(id, jwt) {
        return Api.get('/workout/watch/' + id, config(jwt));
    }
}

let config = (jwt) => {
    return {
        headers: {
            Authorization: 'Bearer ' + jwt,
        }
    }
}

export default new WorkoutApi();
