import { action, observable } from 'mobx';
import ProductApi from 'api/ProductApi';

class ProductEditStore {
    @observable loading;
    @observable show = false;

    @observable product;
    @observable editableProduct;
    @observable canProductBeDeleted = false;

    @observable editEnabled = false;

    @observable editError = false;
    @observable editErrorMessage = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    setValue(name, value) {
        this[name] = value;
    }

    @action
    setProductValue(field, value) {
        this.editableProduct[field] = value;
    }

    @action
    toggleEdit() {
        if (this.editEnabled) {
            this.setValue('editableProduct', JSON.parse(JSON.stringify(this.product)));
            this.disableEditError();
        }

        this.setValue('editEnabled', !this.editEnabled)
    }

    @action
    getProductById(id) {
        this.setValue('loading', true);

        getProductById(id, this.rootStore.user.token)
            .then((data) => {
                if (data) {
                    this.setValue('product', data.product);
                    this.setValue('editableProduct', data.product);
                    this.setValue('canProductBeDeleted', data.canProductBeDeleted);
                    this.disableEditError();
                    this.setValue('loading', false);
                } else {
                    this.setValue('show', false);
                    this.setValue('loading', false);
                }
            })
    }

    @action
    deleteProduct() {
        this.setValue('loading', true);

        deleteProductByIdPromise(this.product.id, this.rootStore.user.token)
            .then((data) => {
                this.setValue('show', false);
                this.setValue('loading', false);
                this.rootStore.productAdmin.list.getData();
            })
            .catch((message) => {
                this.enableEditError(message);
                this.setValue('loading', false);
            })
    }

    @action
    patchProduct() {
        this.setValue('loading', true);

        patchProductPromise(this.editableProduct,  this.rootStore.user.token)
            .then(() => {
                this.getProductById(this.product.id);
                this.setValue('editEnabled', false);
                this.setValue('loading', false);
                this.rootStore.productAdmin.list.getData();
            })
            .catch((message) => {
                this.enableEditError(message);
                this.setValue('loading', false);
            });
    }

    @action
    enableProduct() {
        this.setProductValue('disabled', false);
        this.patchProduct();

    }

    @action
    disableProduct() {
        this.setProductValue('disabled', true);
        this.patchProduct();
    }

    @action
    enableEditError(message) {
        this.setValue('editErrorMessage', message);
        this.setValue('editError', true);
    }

    @action
    disableEditError() {
        this.setValue('editError', false);
        this.setValue('editErrorMessage', null);
    }

    @action
    onClose() {
        this.setValue('show', false);
        this.setValue('editEnabled', false);
    }

    @action
    onOpen(id) {
        this.getProductById(id);
        this.setValue('show', true);
        this.setValue('editEnabled', false);
    }

}

const deleteProductByIdPromise = (id, jwt) => {
    return new Promise((resolve, reject) => {
        ProductApi.deleteProductById(id, jwt)
            .then(() => resolve())
            .catch(() => reject('COMMON.SERVER_ERROR'));
    })
}

const getProductById = (id, jwt) => {
    return new Promise((resolve, reject) => {
        ProductApi.getProductById(id, jwt)
            .then(({ data }) => resolve(data))
            .catch(() => reject());
    })
}

const patchProductPromise = (body, jwt) => {
    return new Promise((resolve, reject) => {
        ProductApi.patchProduct(body, jwt)
            .then(({ data }) => resolve(data))
            .catch((res) => reject('COMMON.SERVER_ERROR'));
    })
}

export default ProductEditStore;
