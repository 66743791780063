import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'i18n/i18n';
import { Badge } from 'react-bootstrap';

@translate()
class ProductStatusCell extends Component {
    render() {
        const { t, row } = this.props;
        const { disabled } = row;

        if (disabled) {
            return (<Badge variant="danger">{t('COMMON.DISABLED')}</Badge>);
        } else {
            return (<Badge variant="success">{t('COMMON.ENABLED')}</Badge>);
        }


    }
}

ProductStatusCell.propTypes = {
    row: PropTypes.any,
}

export default ProductStatusCell;
