import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Alert, Col } from 'react-bootstrap';
import { translate } from 'i18n/i18n';
import WorkoutFreeList from 'app/modules/workout/workoutList/WorkoutFreeList';
import WorkoutPaidList from 'app/modules/workout/workoutList/WorkoutPaidList';

@withRouter
@translate()
@inject('store')
@observer
class Workout extends Component {
    constructor() {
        super();
        this.myInput = React.createRef();
        this.state = { inputHeight: 0 };
    }

    componentDidMount() {
        this.setState({ inputHeight: this.myInput.current ? ((this.myInput.current.offsetWidth - 30) / 4 * 3) : 0 });
    }

    render() {
        const { t, history } = this.props
        const { isOnlineSubscriptionValid } = this.props.store.user;

        const goToProducts = () => history.push('/subscription');

        return (
            <Fragment>
                <div className="position-relative overflow-hidden landing">
                    <Col xs={11} md={9} lg={8} xl={8} className="mx-auto tab-box">
                        <Col md={12}>
                            <h4 className="text-center workouts-online-title">
                                {t('COMMON.WORKOUTS_ONLINE').toUpperCase()}
                            </h4>
                        </Col>

                        <hr width="100%" />

                        <Col md={12} className="mb-5 mt-2">
                            <div className="text-center">
                            <span className="workouts-free-title">
                                <span className="bold">
                                    {t('COMMON.FREE_WORKOUTS_TITLE1')}
                                </span>
                                {' '}
                                <span>
                                    {t('COMMON.FREE_WORKOUTS_TITLE2')}
                                </span>
                            </span>
                            </div>
                        </Col>

                        <Col md={12}>
                            <WorkoutFreeList />
                        </Col>

                        <hr width="100%" />

                        <Col md={12}>
                            <h4 className="text-center workouts-online-title pt-0">
                                {t('COMMON.WORKOUTS_ONLINE_SUB').toUpperCase()}
                            </h4>
                        </Col>

                        <Fragment>
                            {!isOnlineSubscriptionValid &&
                                <div className="w-100 text-center mt-4">
                                    <Alert variant="primary" className="text-center d-inline-block">
                                        {t('COMMON.PRODUCT_NOTICE')} <Alert.Link onClick={() => goToProducts()}>
                                        {t('COMMON.PRODUCT_NOTICE_LINK')}</Alert.Link>.
                                    </Alert>
                                </div>
                            }
                            <WorkoutPaidList />
                        </Fragment>


                    </Col>
                </div>
                {/*
                <div className="position-relative overflow-hidden landing">
                    <Col xs={11} md={8} lg={6} xl={6} className="mx-auto tab-box">
                        <Col md={12}>
                            <h4 className="text-center workouts-online-title">
                                {t('COMMON.ABOUT_WORKOUTS').toUpperCase()}
                            </h4>

                            <hr width="100%" />

                            <Col lg={{ span: 8, offset: 2 }}
                                 md={{ span: 8, offset: 2 }}
                                 xs={{ span: 10, offset: 1 }} ref={this.myInput} className="mb-4">
                                <div className="text-center">
                                    <div className="iframe-box" style={{ height: this.state.inputHeight }}>
                                        <iframe
                                            title="About Workout"
                                            className="responsive-iframe"
                                            style={{ height: this.state.inputHeight }}
                                            src={t('COMMON.APIE_TRENIRUOTES_LINK')}
                                            allowFullScreen={true}
                                            frameBorder={0} />
                                        <div className="iframe-substitute"></div>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                    </Col>
                </div>
                */}
            </Fragment>
        );
    }
}

export default Workout;
