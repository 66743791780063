import React, { Component, Fragment } from 'react';
import 'scss/landing.scss'
import { Col, Tabs, Tab } from 'react-bootstrap';
import LandingTimetable from 'app/modules/landing/components/LandingTimetable';
import { translate } from 'i18n/i18n';
import SubscriptionDisplayTab from 'app/modules/user/components/SubscriptionDisplayTab';
import InformationTab from 'app/modules/user/components/InformationTab';
import UserPaymentListTab from 'app/modules/user/components/UserPaymentListTab';
import { inject, observer } from 'mobx-react';

@translate()
@inject('store')
@observer
class User extends Component {
    render() {
        const { t } = this.props;
        const { userLoading } = this.props.store.user;

        return (
            <div className="position-relative overflow-hidden landing">
                <LandingTimetable />


                <Col xs={11} md={9} lg={8} xl={7} className="mx-auto tab-box pb-0">
                    <div className="user-tab">
                        <Tabs defaultActiveKey="subscription" id="uncontrolled-tab-example" unmountOnExit>
                            <Tab eventKey="subscription" title={t('COMMON.SUBSCRIPTION')}>
                                <SubscriptionDisplayTab />
                            </Tab>
                            <Tab eventKey="payment" title={t('COMMON.PAYMENTS')}>
                                {userLoading ? <Fragment /> : <UserPaymentListTab />}
                            </Tab>
                            <Tab eventKey="contacts" title={t('COMMON.INFORMATION')}>
                                <InformationTab />
                            </Tab>
                        </Tabs>
                    </div>
                </Col>
            </div>
        )
    }
}

export default User;
