import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Spinner } from 'react-bootstrap';

class ModalConfirmActionBtn extends Component {
    constructor() {
        super();
        this.state = { showQuestion: false };
    }

    toggleShow() {
        this.setState({ showQuestion: !this.state.showQuestion });
    }

    getVariant() {
        const { primaryBtn, warningBtn } = this.props;

        if (primaryBtn) {
            return 'primary';
        }

        if (warningBtn) {
            return 'warning';
        }

        return 'danger';
    }

    render() {
        const { text, confirmText, questionText, cancelText, loading, onConfirm, disabled } = this.props;
        const { showQuestion } = this.state;

        const variant = this.getVariant();

        return (
            <Fragment>
                {!showQuestion ?
                    <Button variant={variant}
                            className="ml-2" onClick={() => this.toggleShow()} disabled={loading || disabled}>
                        {text}
                        {loading &&
                            <Spinner as="span" animation="border" size="custom" role="status" />
                        }
                    </Button>
                    :
                    <Alert variant="primary mt-3" className="text-center">
                        <span className="mr-2">{questionText}</span>
                        <Button variant="light" className="mr-2" onClick={() => this.toggleShow()}>
                            {cancelText}
                        </Button>
                        <Button variant={variant}
                                onClick={() => {onConfirm(); this.toggleShow()}} disabled={loading}>
                            {confirmText}
                            {loading &&
                                <Spinner as="span" animation="border" size="custom" role="status" />
                            }
                        </Button>
                    </Alert>
                }
            </Fragment>
        );
    }
}

ModalConfirmActionBtn.propTypes = {
    text: PropTypes.string.isRequired,
    confirmText: PropTypes.string.isRequired,
    cancelText: PropTypes.string.isRequired,
    questionText: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    primaryBtn: PropTypes.bool,
    warningBtn: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default ModalConfirmActionBtn;
