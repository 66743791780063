import { action, observable } from 'mobx';

class EditModal {
    @observable form = {}
    @observable patchPromise;
    @observable onSuccess;

    @observable deletePromise;

    @observable show = false;

    @observable errorEnabled;
    @observable errorMessage;

    @observable loading = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    openModal(data) {
        Object.entries(data).map(([key, value]) => this.setFormValue(key, value));
        this.setShow(true)
    }

    @action
    setShow(value) {
        this.show = value;
    }

    @action
    closeModal() {
        this.setShow(false);
    }

    @action
    setFormValue(fieldName, value) {
        this.form[fieldName] = value;
        this.errorEnabled = false;
    }

    @action
    patch() {
        this.setLoading(true);
        this.patchPromise(this.form, this.rootStore.user.token)
            .then(() => {
                this.setLoading(false);
                this.onSuccess();
            })
            .catch((message) => {
                this.enableErrorAlert(message);
                this.setLoading(false);
            })
    }

    @action
    delete() {
        this.setLoading(true);
        this.deletePromise(this.form.id, this.rootStore.user.token)
            .then(() => {
                this.setLoading(false);
                this.onSuccess();
            })
            .catch((message) => {
                this.enableErrorAlert(message);
                this.setLoading(false);
            })
    }

    @action
    setLoading(value) {
        this.loading = value;
    }

    @action
    enableErrorAlert(message) {
        this.errorMessage = message;
        this.errorEnabled = true;
    }
}

export default EditModal;
