import Api from 'api/api';

class ProductApi {
    postProductUpload(body, jwt) {
        return Api.post('/product/upload', body, config(jwt));
    }

    getProducts() {
        return Api.get('/public/product/list');
    }

    getProduct(id, jwt) {
        return Api.get(`/product/info/${id}`, config(jwt));
    }

    postApplyManualSubscription(body, jwt) {
        return Api.post('/product/apply/manual', body, config(jwt));
    }

    getPaginatedProductAdminList(pagination, jwt) {
        return Api.get('/product/admin/list?' + pagination, config(jwt));
    }

    getProductById(id, jwt) {
        return Api.get('/product/admin/' + id, config(jwt));
    }

    patchProduct(body, jwt) {
        return Api.patch('/product/patch', body, config(jwt));
    }

    deleteProductById(id, jwt) {
        return Api.delete('/product/delete/' + id, config(jwt));
    }
}

let config = (jwt) => {
    return {
        headers: {
            Authorization: 'Bearer ' + jwt,
        }
    }
}

export default new ProductApi();
