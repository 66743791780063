import { action, observable } from 'mobx';

class LoaderStore {
    @observable loading = false;

    @action
    enable() {
        this.loading = true;
    }

    @action
    disable() {
        this.loading = false;
    }
}


export default LoaderStore;
