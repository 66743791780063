import React, { Component, Fragment } from 'react';
import {
    Alert,
    Button,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    Modal,
    Spinner
} from 'react-bootstrap';
import Req from 'app/components/Req';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import FormValidators from 'app/components/validators/FormValidators';
import ModalConfirmActionBtn from 'app/components/modals/ModalConfirmActionBtn';

@translate()
@inject('store')
@observer
class WorkoutEditFormModal extends Component {
    submitForm() {
        this.props.store.workout.editForm.patch();
    }

    valueChange(fieldName, value) {
        this.props.store.workout.editForm.setFormValue(fieldName, value);
    }

    isFormValid() {
        const { title, url, description } = this.props.store.workout.editForm.form;

        const { empty } = new FormValidators();

        return !empty(title) && !empty(url) && !empty(description);
    }

    render() {
        const { t, show } = this.props;
        const { editForm } = this.props.store.workout;
        const { form: { title, url, description, isFree, id }, loading, errorEnabled, errorMessage } = editForm;

        const valueChange = fieldName => e => this.valueChange(fieldName, e.target.value)

        return (
            <Fragment>
                <Form onSubmit={() => {}}>
                    <Modal size="md" show={show} onHide={() => editForm.closeModal()}>
                        <Modal.Header>
                            <span className="font-weight-bolder">{t('COMMON.EDIT_WORKOUT')}</span>
                        </Modal.Header>
                        <Modal.Body>

                            <FormGroup>
                                <FormLabel>{t('COMMON.TITLE')}<Req /></FormLabel>
                                <FormControl type="text" value={title} onChange={valueChange('title')} />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>{t('COMMON.LINK')}<Req /></FormLabel>
                                <FormControl type="text" value={url} onChange={valueChange('url')} />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>{t('COMMON.DESCRIPTION')}<Req /></FormLabel>
                                <FormControl as="textarea" rows={2} value={description}
                                             onChange={valueChange('description')} />
                            </FormGroup>
                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input"
                                           id={`isFreeWorkoutEdit${id}`}
                                           checked={isFree}
                                           onChange={() => this.valueChange('isFree', !isFree)}
                                    />
                                    <label className="custom-control-label pointer" htmlFor={`isFreeWorkoutEdit${id}`}>
                                        {t('COMMON.IS_WORKOUT_FREE')}
                                    </label>
                                </div>
                            </div>

                            {errorEnabled &&
                                <Alert variant="warning" className="text-center">{t(errorMessage)}</Alert>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group float-right w-100 text-right">
                                <ModalConfirmActionBtn
                                    text={t('COMMON.DELETE')}
                                    confirmText={t('COMMON.YES')}
                                    cancelText={t('COMMON.NO')}
                                    questionText={t('COMMON.REALLY_DELETE_QUESTION')}
                                    onConfirm={() => editForm.delete()}
                                    loading={loading} />

                                <Button variant="primary" type="submit" className="ml-2"
                                        disabled={loading || !this.isFormValid()}
                                        onClick={() => this.submitForm()}>
                                    {t('COMMON.UPDATE')}
                                    {loading &&
                                        <Spinner as="span" animation="border" size="custom" role="status" />
                                    }
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </Form>
            </Fragment>
        )
    }
}

export default WorkoutEditFormModal;
