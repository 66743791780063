import Api from 'api/api';

class WorkoutApi {
    getTimetable() {
        return Api.get('/public/timetable');
    }

    postTimetable(body, jwt) {
        return Api.post('/public/timetable/post', body, config(jwt));
    }
}

let config = (jwt) => {
    return {
        headers: {
            Authorization: 'Bearer ' + jwt,
        }
    }
}

export default new WorkoutApi();
