import React, { Component, Fragment } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import 'scss/global.scss'
import MainNavbar from 'app/layout/components/Navbar';
import Landing from 'app/modules/landing/Landing';
import Footer from 'app/layout/components/Footer';
import Policy from 'app/modules/policy/Policy';
import PasswordReset from 'app/modules/landing/PasswordReset';
import Loading from 'app/components/Loading';
import { inject, observer } from 'mobx-react';
import User from 'app/modules/user/User';
import Admin from 'app/modules/admin/Admin';
import Subscription from 'app/modules/subscription/Subscription';
import PaymentForm from 'app/modules/payment/PaymentForm';
import PaymentCallback from 'app/modules/payment/PaymentCallback';
import Workout from 'app/modules/workout/Workout';
import WorkoutPaidPage from 'app/modules/workout/workoutPage/WorkoutPaidPage';
import WorkoutFreePage from 'app/modules/workout/workoutPage/WorkoutFreePage';

@withRouter
@inject('store')
@observer
class Layout extends Component {
    componentDidMount() {
        this.props.store.user.jwtAuthentication();
    }

    render() {
        const { loggedIn, userLoading, isAdmin } = this.props.store.user;

        return (
            <Fragment>
                <Loading />

                <Fragment>
                    <MainNavbar />
                    <Switch>
                        {/* Public paths*/}
                        <Route exact path="/" render={() => <Landing />} />
                        <Route exact path="/password/reset/:jwt" render={() => <PasswordReset />} />
                        <Route exact path="/workout" render={() => <Workout />} />
                        <Route path="/policy" render={() => <Policy />} />
                        <Route path="/subscription" render={() => <Subscription />} />

                        <Route path="/payment/callback" render={() => <PaymentCallback />} />

                        <Route exact path="/workout/free/:id" render={() => <WorkoutFreePage />} />

                        {/* User role paths*/}
                        {(loggedIn || userLoading) &&
                        <Route exact path="/workout/paid/:id" render={() => <WorkoutPaidPage />} />}

                        {(loggedIn || userLoading) && <Route exact path="/user" render={() => <User />} />}
                        {(loggedIn) && <Route path="/pay/:id" render={() => <PaymentForm />} />}

                        {/* Admin role paths*/}
                        {(isAdmin || userLoading) && <Route exact path="/admin" render={() => <Admin />} />}

                        {/* Todo: create redirect to 404*/}
                        <Route render={() => (<Redirect to="/" />)} />
                    </Switch>
                    <Footer />
                </Fragment>
            </Fragment>
        )
    }
}

export default Layout;
