import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { withRouter } from 'react-router-dom';

@translate()
@withRouter
@inject('store')
@observer
class SubscriptionDisplayTab extends Component {
    dateWithMin(subscription) {
        return moment(subscription).format('YYYY-MM-DD HH:mm');
    }

    date(subscription) {
        return moment(subscription).format('YYYY-MM-DD');
    }

    render() {
        const { t, history } = this.props;
        const {
            loggedIn, isSubscriptionValid, subscriptionDaysLeft, subscription,
            subscriptionOnline, isOnlineSubscriptionValid, subscriptionOnlineDaysLeft
        } = this.props.store.user;

        const toSubscription = () => history.push('/subscription');

        return (
            <Fragment>
                {loggedIn &&
                    <div className="user-custom-tab subscription-display-tab p-3">
                        {(subscription || subscriptionOnline) &&
                            <Fragment>
                                <div className="sub-content-box sub-display-box">
                                    {isSubscriptionValid ?
                                        <Fragment>
                                            <div className="subscription-display-title">
                                                {t('COMMON.USER_SUBSCRIPTION_UNTIL_1')}
                                                {' '}
                                                <span className="gym-sub">{t('COMMON.USER_SUBSCRIPTION_UNTIL_2')}</span>
                                                {' '}
                                                {t('COMMON.USER_SUBSCRIPTION_UNTIL_3')}
                                            </div>
                                            <div className="subscription-display-date">
                                                {this.date(subscription)}  ({t('COMMON.DATE_INCLUDED')})
                                            </div>
                                            <div className="subscription-display-days">
                                                ({t('COMMON.DAYS_LEFT')}: {subscriptionDaysLeft})
                                            </div>
                                        </Fragment>
                                        :
                                        <div className="subscription-display-title">
                                            {t('COMMON.USER_SUBSCRIPTION_UNTIL_1')}
                                            {' '}
                                            <span className="gym-sub">{t('COMMON.USER_SUBSCRIPTION_UNTIL_2')}</span>
                                            {' '}
                                            {t('COMMON.USER_SUBSCRIPTION_UNTIL_4')}
                                        </div>
                                    }
                                </div>


                                <hr width="100%" />

                                <div className="sub-content-box sub-display-box">
                                    {isOnlineSubscriptionValid ?
                                        <Fragment>
                                            <div className="subscription-display-title">
                                                {t('COMMON.USER_SUBSCRIPTION_UNTIL_1')}
                                                {' '}
                                                <span className="online-sub">
                                                    {t('COMMON.USER_SUBSCRIPTION_UNTIL_2_1')}
                                                </span>
                                                {' '}
                                                {t('COMMON.USER_SUBSCRIPTION_UNTIL_3')}
                                            </div>
                                            <div className="subscription-display-date">
                                                {this.dateWithMin(subscriptionOnline)}
                                            </div>
                                            <div className="subscription-display-days">
                                                ({t('COMMON.DAYS_LEFT')}: {subscriptionOnlineDaysLeft})
                                            </div>
                                        </Fragment>
                                        :
                                        <div className="subscription-display-title">
                                            {t('COMMON.USER_SUBSCRIPTION_UNTIL_1')}
                                            {' '}
                                            <span className="online-sub">
                                                {t('COMMON.USER_SUBSCRIPTION_UNTIL_2_1')}
                                            </span>
                                            {' '}
                                            {t('COMMON.USER_SUBSCRIPTION_UNTIL_4')}
                                        </div>
                                    }
                                </div>


                                <hr width="100%" />
                            </Fragment>
                        }

                        <div className="sub-content-box">
                            {(subscription || subscriptionOnline) ?
                                <Button variant="outline-primary" className="btn-extend-sub" onClick={toSubscription}>
                                    {t('COMMON.EXTEND_SUBSCRIPTION')}
                                </Button>
                                :
                                <Fragment>
                                    <div className="subscription-display-end">
                                        <span>
                                            {t('COMMON.YOU_DONT_HAVE_SUBSCRIPTION')}
                                        </span>
                                    </div>
                                    <Button variant="outline-primary" className="btn-extend-sub"
                                            onClick={toSubscription}>
                                        {t('COMMON.BUY_SUBSCRIPTION')}
                                    </Button>
                                </Fragment>
                            }
                        </div>
                    </div>
                }
            </Fragment>
        );
    }
}

export default SubscriptionDisplayTab;
