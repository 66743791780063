import React, { Component, Fragment } from 'react';
import {
    Button,
    Col, Container,
    FormControl,
    FormGroup,
    FormLabel,
    Modal, Row,
} from 'react-bootstrap';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SubscriptionCell from 'app/components/table/SubscriptionCell';
import SubscriptionOnlineCell from 'app/components/table/SubscriptionOnlineCell';

@translate()
@inject('store')
@observer
class PaymentViewModal extends Component {
    isSubscriptionValid(subscription) {
        return moment(subscription).startOf('day').diff(moment().startOf('day'), 'days') >= 0 ?
            moment(subscription).format('yyyy-MM-DD') :
            this.props.t('COMMON.SUBSCRIPTION_NOT_VALID');
    }

    isSubscriptionOnlineValid(subscription) {
        return moment(subscription).diff(moment().utc(true)) >= 0 ?
            moment(subscription).format('yyyy-MM-DD HH:mm') :
            this.props.t('COMMON.SUBSCRIPTION_NOT_VALID');
    }

    render() {
        const { t, show, row, onClose } = this.props;

        if (!show && !row) {return null}

        const { title, user: { firstName, lastName, subscription, subscriptionOnline }, paid, created, orderId,
            amount, currency, isCompanyInvoice,
            companyName, companyCode, companyAddress
        } = row;

        return (
            <Fragment>
                <Modal size="lg" show={show} onHide={() => onClose()}>
                    <Modal.Header>
                        <span className="font-weight-bolder">{t('COMMON.PAYMENT_INFORMATION')}</span>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <FormLabel>{t('COMMON.USER')}</FormLabel>
                                        <FormControl type="text" value={firstName + ' ' + lastName} disabled />
                                    </FormGroup>
                                </Col>

                                <Col md={6}>
                                    <FormGroup>
                                        <FormLabel>{t('COMMON.SUBSCRIPTION_TITLE')}</FormLabel>
                                        <FormControl type="text" value={title} disabled />
                                    </FormGroup>
                                </Col>

                                <Col md={6}>
                                    <FormGroup>
                                        <FormLabel>{t('COMMON.ORDER_ID')}</FormLabel>
                                        <FormControl type="text" value={orderId} disabled />
                                    </FormGroup>
                                </Col>

                                <Col md={6}>
                                    <FormGroup>
                                        <FormLabel>{t('COMMON.TOTAL_PRICE')}</FormLabel>
                                        <FormControl type="text"
                                                     value={amount.toFixed(2) + ' ' + currency}
                                                     disabled />
                                    </FormGroup>
                                </Col>

                                <Col md={6}>
                                    <FormGroup>
                                        <FormLabel>{t('COMMON.CREATION_DATE')}</FormLabel>
                                        <FormControl type="text"
                                                     value={moment(created).format('yyyy-MM-DD HH:mm')}
                                                     disabled />
                                    </FormGroup>
                                </Col>

                                <Col md={6}>
                                    {paid &&
                                        <FormGroup>
                                            <FormLabel>{t('COMMON.PAYMENT_PAID')}</FormLabel>
                                            <FormControl
                                                type="text"
                                                value={moment(paid).format('yyyy-MM-DD HH:mm')}
                                                disabled />
                                        </FormGroup>
                                    }
                                </Col>

                                {isCompanyInvoice &&
                                    <Fragment>
                                        <hr width="100%" className="mt-3 mb-3" />

                                        <Col md={6}>
                                            <FormGroup>
                                                <FormLabel>{t('COMMON.COMPANY_NAME')}</FormLabel>
                                                <FormControl type="text" value={companyName} disabled />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <FormLabel>{t('COMMON.COMPANY_CODE')}</FormLabel>
                                                <FormControl type="text" value={companyCode} disabled />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <FormLabel>{t('COMMON.COMPANY_ADDRESS')}</FormLabel>
                                                <FormControl type="text" value={companyAddress} disabled />
                                            </FormGroup>
                                        </Col>
                                    </Fragment>
                                }

                                <Fragment>
                                    <hr width="100%" className="mt-3 mb-3" />

                                    <Col md={6}>
                                        <FormGroup>
                                            <FormLabel>{t('COMMON.USER_SUBSCRIPTION')}</FormLabel>
                                            <div>
                                                <SubscriptionCell subscription={subscription} withDays />
                                            </div>
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <FormLabel>{t('COMMON.USER_ONLINE_SUBSCRIPTION')}</FormLabel>
                                            <div>
                                                <SubscriptionOnlineCell subscription={subscriptionOnline} withDays />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Fragment>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="form-group float-right w-100 text-right">
                            <Button variant="primary" type="submit"
                                    onClick={() => onClose()}>
                                {t('COMMON.CLOSE')}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }
}

PaymentViewModal.propTypes = {
    show: PropTypes.any,
    row: PropTypes.any,
    onClose: PropTypes.any
}

export default PaymentViewModal;
