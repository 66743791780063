import React, { Component, Fragment } from 'react';
import { Alert, Button, Container, FormControl, FormGroup, FormLabel, Modal } from 'react-bootstrap';
import Req from 'app/components/Req';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import FormValidators from 'app/components/validators/FormValidators';
import ModalConfirmActionBtn from 'app/components/modals/ModalConfirmActionBtn';
import Loader from 'app/components/Loader';

@translate()
@inject('store')
@observer
class ProductEditModal extends Component {
    componentDidMount() {
        this.props.store.productForm.resetForm();
    }

    isFormValid() {
        const { title, price, description, subscriptionPeriod } = this.props.store.productAdmin.edit.editableProduct;

        const { empty } = new FormValidators();

        return !empty(title) && !empty(price) && (Number(price) > 0) && Number.isInteger(Number(price)) &&
            !empty(description) && !empty(subscriptionPeriod) && Number.isInteger(Number(subscriptionPeriod));
    }

    render() {
        const { t } = this.props;
        const { edit } = this.props.store.productAdmin;
        const { show, loading, editableProduct, editEnabled,
            editError, editErrorMessage, canProductBeDeleted } = edit;

        const productValueChange = fieldName => e => edit.setProductValue(fieldName, e.target.value);

        if (!show && !editableProduct) {return null}

        return (
            <Fragment>
                <Container>
                    <Modal size="md" show={show} onHide={() => edit.onClose()}>
                        {(loading && !editableProduct) ?
                            <div className="vh-55">
                                <Loader loading={loading} />
                            </div>
                            :
                            <Fragment>
                                <Modal.Header>
                                    <span className="font-weight-bolder">{t('COMMON.PRODUCT_INFO')}</span>
                                </Modal.Header>
                                <Modal.Body>
                                    {editableProduct.disabled &&
                                        <Alert variant="primary" className="text-center">
                                            {t('COMMON.PRODUCT_DISABLED')}
                                        </Alert>
                                    }
                                    <FormGroup>
                                        <FormLabel>{t('COMMON.TITLE')}<Req /></FormLabel>
                                        <FormControl type="text" value={editableProduct.title}
                                                     onChange={productValueChange('title')}
                                                     disabled={!editEnabled} />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>{t('COMMON.PRICE')}<Req /></FormLabel>
                                        <FormControl type="number"
                                                     value={editableProduct.price}
                                                     disabled={!editEnabled}
                                                     onChange={productValueChange('price')} />
                                    </FormGroup>

                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input"
                                                   id="isSubscriptionFull"
                                                   checked={editableProduct.isSubscriptionFull}
                                                   disabled={editableProduct.isOnlineSubscription ||
                                                       editableProduct.isCreditSubscription || !editEnabled}
                                                   onChange={() => edit.setProductValue(
                                                       'isSubscriptionFull', !editableProduct.isSubscriptionFull)}
                                            />
                                            <label className="custom-control-label pointer"
                                                   htmlFor="isSubscriptionFull">
                                                {t('COMMON.SUBSCRIPTION_IS_FULL')}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input"
                                                   id="isOnlineSubscription"
                                                   checked={editableProduct.isOnlineSubscription}
                                                   disabled={editableProduct.isSubscriptionFull ||
                                                       editableProduct.isCreditSubscription || !editEnabled}
                                                   onChange={() => edit.setProductValue(
                                                       'isOnlineSubscription', !editableProduct.isOnlineSubscription)}
                                            />
                                            <label className="custom-control-label pointer"
                                                   htmlFor="isOnlineSubscription">
                                                {t('COMMON.IS_SUBSCRIPTION_ONLINE')}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input"
                                                   id="isCreditSubscription"
                                                   checked={editableProduct.isCreditSubscription}
                                                   disabled={editableProduct.isOnlineSubscription ||
                                                       editableProduct.isSubscriptionFull || !editEnabled}
                                                   onChange={() =>
                                                       edit.setProductValue(
                                                           'isCreditSubscription',
                                                           !editableProduct.isCreditSubscription)}
                                            />
                                            <label className="custom-control-label pointer"
                                                   htmlFor="isCreditSubscription">
                                                {t('COMMON.SUBSCRIPTION_IS_CREDIT_BASED')}
                                            </label>
                                        </div>
                                    </div>

                                    <FormGroup>
                                        <FormLabel>
                                            {t(editableProduct.isCreditSubscription ?
                                                'COMMON.SUBSCRIPTION_CREDIT' : 'COMMON.SUBSCRIPTION_PERIOD_DAYS')}
                                            <Req />
                                        </FormLabel>
                                        <FormControl type="number" value={editableProduct.subscriptionPeriod}
                                                     disabled={!editEnabled}
                                                     onChange={productValueChange('subscriptionPeriod')} />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>{t('COMMON.DESCRIPTION')}<Req /></FormLabel>
                                        <FormControl as="textarea" rows={3} value={editableProduct.description}
                                                     disabled={!editEnabled}
                                                     onChange={productValueChange('description')} />
                                    </FormGroup>
                                    <Loader loading={loading} />
                                </Modal.Body>
                                <Modal.Footer>
                                    {editError &&
                                        <Alert variant="warning" className="text-center">{t(editErrorMessage)}</Alert>
                                    }

                                    {canProductBeDeleted &&
                                    <ModalConfirmActionBtn
                                        text={t('COMMON.ERASE')}
                                        confirmText={t('COMMON.YES')}
                                        cancelText={t('COMMON.NO')}
                                        questionText={t('COMMON.REALLY_ERASE_PRODUCT')}
                                        onConfirm={() => edit.deleteProduct()}
                                        loading={loading}
                                    />
                                    }

                                    {!editEnabled && (editableProduct.disabled ?
                                        <ModalConfirmActionBtn
                                            text={t('COMMON.ENABLE')}
                                            confirmText={t('COMMON.YES')}
                                            cancelText={t('COMMON.NO')}
                                            questionText={t('COMMON.REALLY_ENABLE_PRODUCT')}
                                            primaryBtn
                                            onConfirm={() => edit.enableProduct()}
                                            loading={loading}
                                        />
                                        :
                                        <ModalConfirmActionBtn
                                            warningBtn
                                            text={t('COMMON.DISABLE')}
                                            confirmText={t('COMMON.YES')}
                                            cancelText={t('COMMON.NO')}
                                            questionText={t('COMMON.REALLY_DISABLE_PRODUCT')}
                                            onConfirm={() => edit.disableProduct()}
                                            loading={loading}
                                        />
                                    )}

                                    {editEnabled ?
                                        <Button variant="danger" className="ml-2 mt-2 mb-2"
                                                onClick={() => edit.toggleEdit()}>
                                            {t('COMMON.DISCARD_CHANGES')}
                                        </Button>
                                        :
                                        <Button variant="outline-primary" className="ml-2 mt-2 mb-2"
                                                onClick={() => edit.toggleEdit()}>
                                            {t('COMMON.EDIT')}
                                        </Button>
                                    }

                                    {editEnabled ?
                                        <Button variant="primary" type="submit" disabled={!this.isFormValid()}
                                                className="ml-2 mt-2 mb-2"
                                                onClick={() => edit.patchProduct()}>
                                            {t('COMMON.SAVE')}
                                        </Button>
                                        :
                                        <Button variant="primary" type="submit"
                                                className="ml-2 mt-2 mb-2"
                                                onClick={() => edit.onClose()}>
                                            {t('COMMON.CLOSE')}
                                        </Button>
                                    }
                                </Modal.Footer>
                            </Fragment>
                        }
                    </Modal>
                </Container>
            </Fragment>
        );
    }
}

export default ProductEditModal;
