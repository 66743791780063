import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import 'scss/global.scss'
import Health from 'app/modules/policy/Health';
import Privacy from 'app/modules/policy/Privacy';

@withRouter
class Policy extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/policy/health" render={() => <Health />} />
                <Route exact path="/policy/privacy" render={() => <Privacy />} />
            </Switch>
        )
    }
}

export default Policy;
