import React, { Component, Fragment } from 'react';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import Loader from 'app/components/Loader';
import { withRouter } from 'react-router-dom';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

@withRouter
@translate()
@inject('store')
@observer
class WorkoutFreeList extends Component {
    componentDidMount() {
        this.props.store.workout.list.getFreeWorkoutList();
    }

    /*
     <div className="iframe-container">
        <iframe
            className="responsive-iframe"
            src={workout.url}
            allowFullScreen={true} scrolling="no" seamless=""
            frameBorder={0}
        ></iframe>
    </div>
     */

    render() {
        const { t, history }= this.props;
        const { list } = this.props.store.workout;
        const { loading, freeWorkouts } = list;

        return (
            <Container>
                <Row>
                    {(loading) ?
                        <div className="vh-55">
                            <Loader loading={loading} />
                        </div>
                        :
                        <Fragment>
                            {(freeWorkouts && freeWorkouts.length > 0) ?
                                <Fragment>
                                    {freeWorkouts.map((workout, i) => {
                                        return (
                                            <Col xs={12} md={6} lg={4} xl={4} key={i}>
                                                <div className="card mb-4 box-shadow">
                                                    <div className="card-header">
                                                        <div className="workout-title">
                                                            {workout.title}
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="workout-desc">
                                                            {workout.description}
                                                        </div>
                                                        <div className="flex-centered">
                                                            <Button size="sm" variant="success"
                                                                    className="btn-block mt-3 workout-watch-btn"
                                                                    onClick={() => history.push(
                                                                        `workout/free/${workout.id}`)}>
                                                                {t('COMMON.WATCH')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="sub-online-item workout-date">
                                                        {moment(workout['created']).format('YYYY-MM-DD')}
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Fragment>
                                :
                                <Fragment>
                                    <div className="w-100 text-center">
                                        <Alert variant="info" className="text-center d-inline-block">
                                            {t('COMMON.WORKOUT_NOT_FOUND_FREE')}
                                        </Alert>
                                    </div>
                                </Fragment>
                            }
                        </Fragment>
                    }
                </Row>
            </Container>
        );
    }
}

export default WorkoutFreeList;
