import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import Pagination from 'app/components/pagination/Pagination';
import ProductAddModal from 'app/modules/admin/components/Product/ProductAddModal';
import ProductTypeCell from 'app/components/table/ProductTypeCell';
import ProductDurationCell from 'app/components/table/ProductDurationCell';
import ProductStatusCell from 'app/components/table/ProductStatusCell';
import ProductEditModal from 'app/modules/admin/components/Product/ProductEditModal';

@translate()
@inject('store')
@observer
class ProductAdminListTab extends Component {
    render() {
        const { t } = this.props;

        const columns = [
            {
                name: t('COMMON.TITLE'),
                selector: 'title',
                sortable: true,
                minWidth: '200px',
            },
            {
                name: t('COMMON.TYPE'),
                selector: 'isOnlineSubscription',
                format: (row) => {return <ProductTypeCell row={row} />},
                sortable: true,
                minWidth: '115px',
                maxWidth: '115px',
                style: { fontSize: '1rem' },
            },
            {
                name: t('COMMON.DURATION'),
                selector: 'subscriptionPeriod',
                format: (row) => {return <ProductDurationCell row={row} />},
                sortable: true,
                minWidth: '100px',
                maxWidth: '100px',
                center: true,
            },
            {
                name: t('COMMON.STATUS'),
                selector: 'disabled',
                format: (row) => {return <ProductStatusCell row={row} />},
                sortable: true,
                minWidth: '115px',
                maxWidth: '115px',
                center: true,
                style: { fontSize: '1rem' },
            },
            {
                name: t('COMMON.PRICE'),
                selector: 'price',
                format: (row) => {return <span className="bolder">{row['price'].toFixed(2)} EUR</span>},
                sortable: true,
                minWidth: '105px',
                maxWidth: '105px',
                right: true
            },
        ];

        const { list, edit } = this.props.store.productAdmin;

        return (
            <Fragment>
                <Pagination paginatedStore={list} columns={columns}
                            addBtn addTitle={t('COMMON.ADD')} onAdd={() => this.props.store.productForm.toggleModal()}
                            pointerOnHover
                            onRowClicked={(row) => {edit.onOpen(row.id)}} />
                <ProductAddModal />
                <ProductEditModal />
            </Fragment>
        );
    }
}

export default ProductAdminListTab;
