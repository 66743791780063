import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
class Loading extends React.Component {
    render() {
        const { loading } = this.props.store.loader;
        const { background, loaderColor, loaderSecondaryColor } = this.props;

        const backgroundColor = { background };
        const loader = { background: loaderColor };
        const loaderSecondary = { background: loaderSecondaryColor };

        return (
            <div className={`${!loading && 'loading-hidden'} loading-background`} style={backgroundColor}>
                <div className="loading-bar">
                    <div className={`${!loading && 'loading-bar-hidden'} loading-circle-1`} style={loader} />
                    <div className={`${!loading && 'loading-bar-hidden'} loading-circle-2`} style={loaderSecondary} />
                </div>
            </div>
        )
    }
}

Loading.defaultProps = {
    loading: false,
    background: 'rgba(236, 240, 241, 1)',
    loaderColor: '#FCE54A',
    loaderSecondaryColor: '#50b4d6'
}

Loading.propTypes = {
    loading: PropTypes.bool,
    background: PropTypes.string,
    loaderColor: PropTypes.string,
    loaderSecondaryColor: PropTypes.string
}

export default Loading
