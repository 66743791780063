import React, { Component } from 'react';
import 'scss/landing.scss'
import { Col } from 'react-bootstrap';
import LandingSocial from 'app/modules/landing/components/LandingSocial';
import LandingIntro from 'app/modules/landing/components/LandingIntro';
import LandingAboutUs from 'app/modules/landing/components/LandingAboutUs';
import LandingTimetable from 'app/modules/landing/components/LandingTimetable';
import LandingDivider from 'app/modules/landing/components/LandingDivider';

class Landing extends Component {
    render() {
        return (
            <div className="position-relative overflow-hidden text-center landing">
                <LandingTimetable />

                <LandingIntro />

                <Col xs={10} md={10} lg={8} xl={7} className="bg-dark box-shadow mx-auto box2 pt-1">
                    <LandingAboutUs />

                    <LandingDivider />

                    <LandingSocial />
                </Col>
            </div>
        )
    }
}

export default Landing;
