import LandingStore from 'store/LandingStore/LandingStore';
import ModalStore from 'store/ModalStore/ModalStore';
import UserStore from 'store/UserStore/UserStore';
import UserPasswordResetStore from 'store/UserStore/UserPasswordResetStore';
import LoaderStore from 'store/LoaderStore/LoaderStore';
import UserEmailResetStore from 'store/UserStore/UserEmailResetStore';
import ProductFormStore from 'store/ProductStore/ProductFormStore';
import ProductStore from 'store/ProductStore/ProductStore';
import PaymentStore from 'store/PaymentStore/PaymentStore';
import WorkoutStore from 'store/WorkoutStore/WorkoutStore';
import TimetableStore from 'store/TimetableStore/TimetableStore';
import UserAdminStore from 'store/UserStore/UserAdminStore';
import ProductAdminStore from 'store/ProductStore/ProductAdminStore';

class RootStore {
    constructor() {
        this.landing = new LandingStore(this);
        this.modal = new ModalStore(this);
        this.user = new UserStore(this);
        this.userAdmin = new UserAdminStore(this);
        this.password = new UserPasswordResetStore(this);
        this.email = new UserEmailResetStore(this);
        this.loader = new LoaderStore(this);
        this.workout = new WorkoutStore(this);
        this.productForm = new ProductFormStore(this);
        this.product = new ProductStore(this);
        this.productAdmin = new ProductAdminStore(this);
        this.payment = new PaymentStore(this);
        this.timetable = new TimetableStore(this);
    }
}

const rootStore = new RootStore();

export default rootStore;
