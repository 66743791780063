import React, { Component } from 'react';
import { Button, Col } from 'react-bootstrap';
import { translate } from 'i18n/i18n';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

@withRouter
@translate()
@inject('store')
@observer
class LandingIntro extends Component {
    render() {
        const { t, history } = this.props;

        return (
            <Col md={10} className="mx-auto my-5">
                <h1 className="landing-title">{t('COMMON.TITLE_FULL').toUpperCase()}</h1>
                <p className="lead font-weight-normal mt-3 landing-intro">{t('COMMON.LANDING_PAGE_INTRO')}</p>
                <Col xs={12} md={{ span: 8, offset: 2 }} className="flex-row landing-action p-0">
                    <Button onClick={() => history.push('/subscription')} variant="outline-primary">
                        {t('COMMON.SUBSCRIPTIONS')}
                    </Button>
                    <Button onClick={() => history.push('/workout')} variant="primary">
                        {t('COMMON.SPORT_ONLINE')}
                    </Button>
                </Col>
            </Col>
        );
    }
}

export default LandingIntro;
