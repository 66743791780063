import { action, observable } from 'mobx';
import UserApi from 'api/UserApi';
import ProductApi from 'api/ProductApi';

class UserAdminEditStore {
    @observable loading;
    @observable show;
    @observable user;
    @observable editableUser;
    @observable canUserBeDeleted = false;

    @observable subscriptionDays = '';
    @observable subscriptionOnlineDays = '';

    @observable editEnabled = false;
    @observable editError = false;
    @observable editErrorMessage = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    setValue(name, value) {
        this[name] = value;
    }

    @action
    setUserValue(field, value) {
        this.editableUser[field] = value;
    }

    @action
    toggleEdit() {
        if (this.editEnabled) {
            this.setValue('editableUser', JSON.parse(JSON.stringify(this.user)));
            this.disableEditError();
        }

        this.setValue('editEnabled', !this.editEnabled)
    }

    @action
    getUserById(id) {
        this.setValue('loading', true);

        getUserByIdPromise(id, this.rootStore.user.token)
            .then((data) => {
                if (data) {
                    this.setValue('user', data.user);
                    this.setValue('editableUser', data.user);
                    this.setValue('canUserBeDeleted', data.canUserBeDeleted);
                    this.disableEditError();
                    this.setValue('loading', false);
                } else {
                    this.setValue('show', false);
                    this.setValue('loading', false);
                }
            })
    }

    @action
    deleteUser() {
        this.setValue('loading', true);

        deleteUserByIdPromise(this.user.id, this.rootStore.user.token)
            .then((data) => {
                this.setValue('show', false);
                this.setValue('loading', false);
                this.rootStore.userAdmin.list.getData();
            })
            .catch((message) => {
                this.enableEditError(message);
                this.setValue('loading', false);
            })
    }

    @action
    inviteUser() {
        this.setValue('loading', true);

        inviteUserPromise(this.user.id, this.rootStore.user.token)
            .then(() => {
                this.getUserById(this.user.id);
                this.rootStore.userAdmin.list.getData();
                this.setValue('loading', false);
            })
            .catch((message) => {
                this.enableEditError(message);
                this.setValue('loading', false);
            })
    }

    @action
    postAddSubscriptionDays() {
        const body = {
            userId: this.user.id,
            isOnlineSubscription: false,
            subscriptionPeriod: this.subscriptionDays
        }

        this.postAddSubscriptionRequest(body);

        this.setValue('subscriptionDays', '');
    }

    @action
    postAddSubscriptionOnlineDays() {
        const body = {
            userId: this.user.id,
            isOnlineSubscription: true,
            subscriptionPeriod: this.subscriptionOnlineDays
        }

        this.postAddSubscriptionRequest(body);
        this.setValue('subscriptionOnlineDays', '');
    }

    @action
    postAddSubscriptionRequest(body) {
        this.setValue('loading', true);

        postApplyManualSubscriptionPromise(body,  this.rootStore.user.token)
            .then(() => {
                this.getUserById(this.user.id);
                this.rootStore.userAdmin.list.getData();
                this.setValue('loading', false);
            })
            .catch(() => {
                this.getUserById(this.user.id);
                this.rootStore.userAdmin.list.getData();
                this.setValue('loading', false);
            });
    }

    @action
    patchUser() {
        this.setValue('loading', true);

        patchUserPromise(this.editableUser,  this.rootStore.user.token)
            .then(() => {
                this.getUserById(this.user.id);
                this.setValue('editEnabled', false);
                this.setValue('loading', false);
                this.rootStore.userAdmin.list.getData();
            })
            .catch((message) => {
                this.enableEditError(message);
                this.setValue('loading', false);
            });
    }

    @action
    enableEditError(message) {
        this.setValue('editErrorMessage', message);
        this.setValue('editError', true);
    }

    @action
    disableEditError() {
        this.setValue('editError', false);
        this.setValue('editErrorMessage', null);
    }

    @action
    onClose() {
        this.setValue('show', false);
        this.setValue('editEnabled', false);
    }

    @action
    onOpen(id) {
        this.getUserById(id);
        this.setValue('show', true);
        this.setValue('editEnabled', false);
    }

}

const deleteUserByIdPromise = (id, jwt) => {
    return new Promise((resolve, reject) => {
        UserApi.deleteUserById(id, jwt)
            .then(() => resolve())
            .catch(() => reject('COMMON.SERVER_ERROR'));
    })
}

const inviteUserPromise = (id, jwt) => {
    return new Promise((resolve, reject) => {
        UserApi.postUserInviteMail(id, jwt)
            .then(() => resolve())
            .catch((res) => {
                if (res.response && res.response.data && res.response.data.message) {
                    reject(res.response.data.message)
                } else {
                    reject('COMMON.SERVER_ERROR');
                }
            });
    })
}

const getUserByIdPromise = (id, jwt) => {
    return new Promise((resolve, reject) => {
        UserApi.getUserById(id, jwt)
            .then(({ data }) => resolve(data))
            .catch(() => reject());
    })
}

const postApplyManualSubscriptionPromise = (body, jwt) => {
    return new Promise((resolve, reject) => {
        ProductApi.postApplyManualSubscription(body, jwt)
            .then(({ data }) => resolve(data))
            .catch(() => reject());
    })
}

const patchUserPromise = (body, jwt) => {
    return new Promise((resolve, reject) => {
        UserApi.patchUser(body, jwt)
            .then(({ data }) => resolve(data))
            .catch((res) => {
                if (res.response && res.response.data && res.response.data.message) {
                    reject(res.response.data.message)
                } else {
                    reject('COMMON.SERVER_ERROR');
                }
            });
    })
}

export default UserAdminEditStore;
