import React, { Component, Fragment } from 'react';
import LandingTimetable from 'app/modules/landing/components/LandingTimetable';
import { Col, Tab, Tabs } from 'react-bootstrap';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import WorkoutList from 'app/modules/admin/components/Workout/WorkoutList';
import TimetableForm from 'app/modules/admin/components/Timetable/TimetableForm';
import PaymentAdminListTab from 'app/modules/admin/components/Payment/PaymentAdminListTab';
import UsersAdminListTab from 'app/modules/admin/components/Users/UsersAdminListTab';
import ProductAdminListTab from 'app/modules/admin/components/Product/ProductAdminListTab';

@translate()
@inject('store')
@observer
class Admin extends Component {
    render() {
        const { t } = this.props;
        const { userLoading } = this.props.store.user;

        return (
            <div className="position-relative overflow-hidden landing">
                <LandingTimetable />


                <Col xs={11} md={9} lg={8} xl={8} className="mx-auto tab-box">
                    <div className="user-tab">
                        <Tabs defaultActiveKey="users" id="uncontrolled-tab-example" unmountOnExit>
                            <Tab eventKey="users" title={t('COMMON.SUBSCRIBERS')}>
                                {userLoading ? <Fragment /> : <UsersAdminListTab />}
                            </Tab>
                            <Tab eventKey="payments" title={t('COMMON.PAYMENTS')}>
                                {userLoading ? <Fragment /> : <PaymentAdminListTab />}
                            </Tab>
                            <Tab eventKey="workout" title={t('COMMON.WORKOUTS')}>
                                {userLoading ? <Fragment /> : <WorkoutList />}
                            </Tab>
                            <Tab eventKey="products" title={t('COMMON.PRODUCTS')}>
                                {userLoading ? <Fragment /> : <ProductAdminListTab />}
                            </Tab>
                            <Tab eventKey="timetable" title={t('COMMON.TIMETABLE')}>
                                <TimetableForm />
                            </Tab>
                        </Tabs>
                    </div>
                </Col>
            </div>
        )
    }
}

export default Admin;
