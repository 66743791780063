import { action, observable } from 'mobx';
import UserApi from 'api/UserApi';

class UserEmailResetStore {
    @observable form = {
        email: '',
        repeatEmail: ''
    }

    @observable invalidFields = {};

    @observable loading = false;

    @observable errorEnabled;
    @observable errorMessage;
    @observable successEnabled;
    @observable successMessage;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    postChangeEmailAuthenticated(jwt) {
        this.setLoading(true);
        postChangeEmailPromise(this.form, jwt)
            .then(({ data }) => {
                this.rootStore.user.authenticateUser(data);
                this.resetForm();
                this.enableSuccessAlert('COMMON.EMAIL_CHANGED_SUCCESS');
                this.setLoading(false);
            })
            .catch((message) => {
                this.enableErrorAlert(message);
                this.setLoading(false);
            })
    }

    @action
    enableSuccessAlert(message) {
        this.successMessage = message;
        this.successEnabled = true;
    }

    @action
    enableErrorAlert(message) {
        this.errorMessage = message;
        this.errorEnabled = true;
    }

    @action
    setFormValue(fieldName, value) {
        this.form[fieldName] = value;
        this.successEnabled = false;
        this.errorEnabled = false;
    }

    @action
    setLoading(value) {
        this.loading = value;
    }

    @action
    setInvalidFields(fieldName) {
        this.invalidFields[fieldName] = true;
    }

    @action
    removeInvalidField(fieldName) {
        this.invalidFields[fieldName] = false;
    }

    @action
    resetForm() {
        this.setFormValue('email', '');
        this.setFormValue('repeatEmail', '');
        this.successEnabled = false;
        this.errorEnabled = false;
    }
}

const postChangeEmailPromise = (body, jwt) => {
    return new Promise((resolve, reject) => {
        UserApi.postEmailChange(body, jwt)
            .then((res) => resolve(res))
            .catch((res) => {
                if (res.response && res.response.data && res.response.data.message) {
                    reject(res.response.data.message)
                } else {
                    reject('COMMON.SERVER_ERROR')
                }
            });
    })
}

export default UserEmailResetStore;
