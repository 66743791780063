import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import logoFull from 'assets/img/logo-full.png';
import 'scss/footer.scss';
import { translate } from 'i18n/i18n';
import moment from 'moment';

@translate()
class Footer extends Component {
    render() {
        const { t } = this.props;
        const year = moment().format('YYYY')
        return (
            <footer className="pt-4 pt-md-5 border-top">
                <Container>
                    <Row>
                        <Col lg={{ span: 4, order: 1 }}
                             md={{ span: 12, order: 1 }}
                             xs={{ span: 12, order: 2 }}
                             className="flex-centered">
                            <img className="mb-2" src={logoFull} alt="" width="200" />
                            <small className="d-block text-muted">{t('COMMON.RIGHTS_RESERVED')} {year}</small>
                            <small className="d-block mb-3 text-muted">
                                Projektą įgyvendino{' '}
                                <a href={'https://www.linkedin.com/in/viktoras-jegorovas/'} target="_blank"
                                   rel="noopener noreferrer">
                                    Viktoras Jegorovas
                                </a>
                            </small>
                        </Col>
                        <Col lg={{ span: 4, offset: 0, order: 2 }}
                             md={{ span: 6, offset: 3, order: 2 }}
                             xs={{ span: 10, offset: 1, order: 3 }}
                             className="contacts mt-3 mb-3">
                            <Container>
                                <Row>
                                    <Col md={12}>
                                        <h5>{t('COMMON.REKVIZITAI').toUpperCase()}</h5>
                                        <ul className="list-unstyled footer-list">
                                            <li>
                                                {t('COMMON.REKVIZITAI_1')}
                                            </li>
                                            <li>
                                                {t('COMMON.REKVIZITAI_2')}
                                            </li>
                                            <li>
                                                {t('COMMON.REKVIZITAI_3')}
                                            </li>
                                            <li>
                                                {t('COMMON.REKVIZITAI_4')}
                                            </li>
                                            <li>
                                                {t('COMMON.REKVIZITAI_5')}
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>

                        <Col lg={{ span: 4, offset: 0, order: 3 }}
                             md={{ span: 6, offset: 3, order: 3 }}
                             xs={{ span: 10, offset: 1, order: 1 }}
                             className="contacts mt-3 mb-3">
                            <Container>
                                <Row>
                                    <Col md={12}>
                                        <h5>{t('COMMON.CONTACTS').toUpperCase()}</h5>
                                        <ul className="list-unstyled footer-list">
                                            <li>
                                                Marius: <a href={'tel:' + t('COMMON.PHONE1')}>{t('COMMON.PHONE1')}</a>
                                            </li>
                                            <li>
                                                Alanas:<a href={'tel:' + t('COMMON.PHONE2')}>{t('COMMON.PHONE2')}</a>
                                            </li>
                                            <li>
                                                <a href={'mailto:' + t('COMMON.MAIN_EMAIL')}>
                                                    {t('COMMON.MAIN_EMAIL')}
                                                </a>
                                            </li>
                                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                            <li><a target="_blank" href="https://www.google.com/maps/place/54%C2%B042'47.4%22N+25%C2%B018'14.7%22E/@54.7131765,25.3036559,114m/data=!3m1!1e3!4m14!1m7!3m6!1s0x46dd96b7540ad775:0xf45ef1273abfff15!2zxb1pcm3Fq27FsyBnLiA2NiwgVmlsbml1cyAwOTEzMQ!3b1!8m2!3d54.7135361!4d25.3037931!3m5!1s0x0:0xf7dbc90a7eaffda3!7e2!8m2!3d54.7131722!4d25.3040733">Žirmūnų g. 66, Vilnius</a></li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}

export default Footer;
