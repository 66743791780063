class FormValidators {
    empty(value) {
        return value === '' || value === null;
    }

    isEmailValid(email) {
        let re = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        return re.test(email);
    }

    isPasswordValid(password) {
        return password.length >= 8;
    }
}

export default FormValidators;
