import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';


class DateCell extends Component {
    render() {
        const { date, format, bold } = this.props;

        return (
            <span style={bold ? { fontWeight: 600 } : {}}>
                {date ? moment(date).format(format ? format : 'yyyy-MM-DD') : null}
            </span>
        )
    }
}

DateCell.propTypes = {
    date: PropTypes.any,
    format: PropTypes.any,
    bold: PropTypes.any,
}

export default DateCell;
