import { action, observable } from 'mobx';
import WorkoutApi from 'api/WorkoutApi';
import EditModal from 'store/Utils/EditModal';

class WorkoutEditFormModalStore extends EditModal {
    @observable form = {
        id: '',
        title: '',
        url: '',
        description: '',
        isFree: false,
    }

    @observable patchPromise;
    @observable deletePromise;
    @observable onSuccess = () => this.refresh();

    constructor(rootStore) {
        super(rootStore);
        this.patchPromise = workoutPatchPromise;
        this.deletePromise = workoutDeletePromise;
    }

    @action
    refresh() {
        this.closeModal();
        this.rootStore.workout.adminList.getData();
    }
}

const workoutPatchPromise = (body, jwt) => {
    return new Promise((resolve, reject) => {
        WorkoutApi.patchWorkout(body, jwt)
            .then((res) => resolve(res))
            .catch(() => reject('COMMON.SERVER_ERROR'));
    })
}

const workoutDeletePromise = (id, jwt) => {
    return new Promise((resolve, reject) => {
        WorkoutApi.deleteWorkout(id, jwt)
            .then((res) => resolve(res))
            .catch(() => reject('COMMON.SERVER_ERROR'));
    })
}

export default WorkoutEditFormModalStore;
