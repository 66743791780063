import React, { Component, Fragment } from 'react';
import {
    Alert,
    Button,
    Col,
    Container,
    FormControl,
    FormGroup,
    FormLabel,
    InputGroup,
    Modal,
    Row
} from 'react-bootstrap';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Loader from 'app/components/Loader';
import SubscriptionCell from 'app/components/table/SubscriptionCell';
import SubscriptionOnlineCell from 'app/components/table/SubscriptionOnlineCell';
import FormValidators from 'app/components/validators/FormValidators';
import DateCell from 'app/components/table/DateCell';
import ModalConfirmActionBtn from 'app/components/modals/ModalConfirmActionBtn';
import moment from 'moment';

@translate()
@inject('store')
@observer
class UserAdminEditModal extends Component {
    addSubscriptionDays(e) {
        e.preventDefault();
        const { edit } = this.props.store.userAdmin;
        edit.postAddSubscriptionDays();
    }

    addSubscriptionOnlineDays(e) {
        e.preventDefault();
        const { edit } = this.props.store.userAdmin;
        edit.postAddSubscriptionOnlineDays();
    }

    canInviteBeSent() {
        const { edit } = this.props.store.userAdmin;
        const { user } = edit;
        const { isEmailValid } = new FormValidators();

        return isEmailValid(user.email) && (!user['inviteSent'] ||
            moment().utc(true).diff(moment(user['inviteSent'])) > 86400000);
    }

    isValueEmpty(value) {
        const { empty } = new FormValidators();
        return empty(value);
    }

    isFormValid() {
        const { email, firstName, lastName } = this.props.store.userAdmin.edit.editableUser;
        const { empty, isEmailValid } = new FormValidators();

        return !((empty(email) || (!empty(email) && isEmailValid(email))) && !empty(firstName) && !empty(lastName));
    }

    render() {
        const { t } = this.props;
        const { edit } = this.props.store.userAdmin;
        const { show, loading, editableUser, subscriptionDays, subscriptionOnlineDays, editEnabled,
            editError, editErrorMessage, canUserBeDeleted, user } = edit;

        const valueChange = fieldName => e => edit.setValue(fieldName, e.target.value);
        const userValueChange = fieldName => e => edit.setUserValue(fieldName, e.target.value);

        if (!show && !editableUser) {return null}

        return (
            <Fragment>
                <Modal size="lg" show={show} onHide={() => edit.onClose()}>
                    <Modal.Header>
                        <span className="font-weight-bolder">{t('COMMON.USER_INFORMATION')}</span>
                    </Modal.Header>
                    <Modal.Body>
                        {(loading && !editableUser) ?
                            <div className="vh-55">
                                <Loader loading={loading} />
                            </div>
                            :
                            <Container>
                                <Row>
                                    <Col md={12}>
                                        <div className="modal-title">{t('COMMON.TOP_UP_SUBSCRIPTION')}</div>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                        <form onSubmit={(e) => {this.addSubscriptionDays(e)}}>
                                            <Row className="flex-align-end">
                                                <Col md={8} xs={8}>
                                                    <FormGroup>
                                                        <FormLabel>{t('COMMON.GYM_HALL')}</FormLabel>
                                                        <InputGroup>
                                                            <FormControl type="number" value={subscriptionDays}
                                                                         onChange={valueChange('subscriptionDays')} />
                                                            <InputGroup.Append>
                                                                <InputGroup.Text id="btnGroupAddon">
                                                                    {t('COMMON.DAYS')}
                                                                </InputGroup.Text>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={4} xs={4}>
                                                    <FormGroup className="d-flex">
                                                        <Button variant="primary" className="ml-auto" type="submit"
                                                                disabled={this.isValueEmpty(subscriptionDays)}>
                                                            {t('COMMON.ADD')}
                                                        </Button>
                                                    </FormGroup>
                                                </Col>


                                                <Col md={12}>
                                                    <FormGroup>
                                                        <div>
                                                            <SubscriptionCell
                                                                subscription={editableUser.subscription} withDays />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </form>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                        <form onSubmit={(e) => {this.addSubscriptionOnlineDays(e)}}>
                                            <Row className="flex-align-end">
                                                <Col md={8} xs={8}>
                                                    <FormGroup>
                                                        <FormLabel>{t('COMMON.ONLINE')}</FormLabel>
                                                        <InputGroup>
                                                            <FormControl type="number" value={subscriptionOnlineDays}
                                                                         onChange={
                                                                             valueChange('subscriptionOnlineDays')
                                                                         } />
                                                            <InputGroup.Append>
                                                                <InputGroup.Text id="btnGroupAddon">
                                                                    {t('COMMON.DAYS')}
                                                                </InputGroup.Text>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4} xs={4}>
                                                    <FormGroup className="d-flex">
                                                        <Button type="submit"
                                                                variant="primary" className="ml-auto"
                                                                disabled={this.isValueEmpty(subscriptionOnlineDays)}>
                                                            {t('COMMON.ADD')}
                                                        </Button>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={12}>
                                                    <FormGroup>
                                                        <div>
                                                            <SubscriptionOnlineCell
                                                                subscription={editableUser.subscriptionOnline}
                                                                withDays />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </form>
                                    </Col>
                                </Row>

                                <hr width="100%" className="mt-1" />

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <FormLabel>{t('COMMON.FIRST_NAME')}</FormLabel>
                                            <FormControl
                                                type="text"
                                                value={editableUser.firstName}
                                                onChange={userValueChange('firstName')}
                                                disabled={!editEnabled} />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <FormLabel>{t('COMMON.LAST_NAME')}</FormLabel>
                                            <FormControl
                                                type="text"
                                                onChange={userValueChange('lastName')}
                                                value={editableUser.lastName}
                                                disabled={!editEnabled} />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <FormLabel>{t('COMMON.EMAIL')}</FormLabel>
                                            <FormControl
                                                type="email"
                                                onChange={userValueChange('email')}
                                                value={editableUser.email}
                                                disabled={!editEnabled} />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <FormLabel>{t('COMMON.LAST_ACTIVE')}</FormLabel>
                                            <FormGroup className="d-flex">
                                                <div className="mt-2">
                                                    {editableUser['lastActive'] ?
                                                        <DateCell
                                                            bold date={editableUser['lastActive']}
                                                            format={'yyyy-MM-DD HH:mm'}
                                                        />
                                                        :
                                                        <span className="bolder">
                                                            {t('COMMON.USER_HAS_NOT_LOGGED_IN')}
                                                        </span>
                                                    }
                                                </div>
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Loader loading={loading} />
                            </Container>
                        }
                    </Modal.Body>
                    <Modal.Footer className="pt-1">
                        {editError &&
                            <Alert variant="warning" className="text-center w-100">{t(editErrorMessage)}</Alert>
                        }
                        <div className="form-group float-right w-100 text-right">
                            {user && !user['lastActive'] &&
                                <ModalConfirmActionBtn
                                    disabled={!this.canInviteBeSent()}
                                    primaryBtn
                                    text={t('COMMON.INVITE')}
                                    confirmText={t('COMMON.YES')}
                                    cancelText={t('COMMON.NO')}
                                    questionText={t('COMMON.INVITE_USER_QUESTION')}
                                    onConfirm={() => edit.inviteUser()}
                                    loading={loading}
                                />
                            }

                            {canUserBeDeleted &&
                                <ModalConfirmActionBtn
                                    text={t('COMMON.ERASE')}
                                    confirmText={t('COMMON.YES')}
                                    cancelText={t('COMMON.NO')}
                                    questionText={t('COMMON.REALLY_ERASE_USER_QUESTION')}
                                    onConfirm={() => edit.deleteUser()}
                                    loading={loading}
                                />
                            }

                            {editEnabled ?
                                <Button variant="danger" className="ml-2 mt-2 mb-2"
                                        onClick={() => edit.toggleEdit()}>
                                    {t('COMMON.DISCARD_CHANGES')}
                                </Button>
                                :
                                <Button variant="outline-primary" className="ml-2 mt-2 mb-2"
                                        onClick={() => edit.toggleEdit()}>
                                    {t('COMMON.EDIT')}
                                </Button>
                            }

                            {editEnabled ?
                                <Button variant="primary" type="submit" disabled={this.isFormValid()}
                                        className="ml-2 mt-2 mb-2"
                                        onClick={() => edit.patchUser()}>
                                    {t('COMMON.SAVE')}
                                </Button>
                                :
                                <Button variant="primary" type="submit"
                                        className="ml-2 mt-2 mb-2"
                                        onClick={() => edit.onClose()}>
                                    {t('COMMON.CLOSE')}
                                </Button>
                            }
                        </div>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }
}

UserAdminEditModal.propTypes = {
    row: PropTypes.any
}

export default UserAdminEditModal;
