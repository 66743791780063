import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Loader from 'app/components/Loader';
import WorkoutView from 'app/modules/workout/workoutPage/WorkoutView';

@withRouter
@inject('store')
@observer
class WorkoutFreePage extends Component {
    componentDidMount() {
        const { match: { params: { id } } } = this.props;
        this.props.store.workout.free.getFreeWorkoutById(id);
    }

    render() {
        const { free } = this.props.store.workout;
        const { loading, workout } = free;

        return (
            <div className="position-relative overflow-hidden landing">
                <Col xs={11} md={9} lg={8} xl={8} className="mx-auto tab-box">
                    {(loading || !workout) ?
                        <div className="vh-55">
                            <Loader loading={true} />
                        </div>
                        :
                        <WorkoutView workout={workout} />
                    }
                </Col>
            </div>
        );
    }
}

export default WorkoutFreePage;
