import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import Pagination from 'app/components/pagination/Pagination';
import WorkoutEditFormModal from 'app/modules/admin/components/Workout/WorkoutEditFormModal';
import moment from 'moment';
import WorkoutAddModal from 'app/modules/admin/components/Workout/WorkoutAddModal';

@translate()
@inject('store')
@observer
class WorkoutList extends Component {
    render() {
        const { t } = this.props;

        const columns = [
            {
                name: t('COMMON.TITLE'),
                selector: 'title',
                sortable: true,
                minWidth: '200px',
            },
            {
                name: t('COMMON.VIEWS'),
                selector: 'views',
                format: (row) => <span className="bolder">{row['views']}</span>,
                sortable: true,
                center: true,
                minWidth: '140px',
                maxWidth: '160px',
            },
            {
                name: t('COMMON.WORKOUT_FREE'),
                selector: 'isFree',
                format: (row) => row['isFree'] ? t('COMMON.YES') : t('COMMON.NO'),
                sortable: true,
                center: true,
                minWidth: '140px',
                maxWidth: '160px',
            },
            {
                name: t('COMMON.CREATION_DATE'),
                selector: 'created',
                format: (row) => {return moment(row['created']).format('YYYY-MM-DD')},
                sortable: true,
                right: true,
                minWidth: '140px',
                maxWidth: '160px',
            }
        ];

        const { adminList, editForm, form } = this.props.store.workout;

        return (
            <Fragment>
                <Pagination paginatedStore={adminList} columns={columns} pointerOnHover
                            addBtn addTitle={t('COMMON.ADD')} onAdd={() => form.toggleModal()}
                            onRowClicked={(row) => {editForm.openModal(row)}}
                />
                <WorkoutEditFormModal show={editForm.show} />
                <WorkoutAddModal />
            </Fragment>
        );
    }
}

export default WorkoutList;
