import React, { Component } from 'react';
import { Button, FormGroup, FormLabel, FormControl, Alert, Spinner } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';
import FormValidators from 'app/components/validators/FormValidators';

@translate()
@inject('store')
@observer
class LoginModalContent extends Component {
    componentDidMount() {
        const { loginModal } = this.props.store.modal;
        loginModal.disableAlerts();
    }

    submitForm(e) {
        e.preventDefault();
        this.props.store.modal.loginModal.postLogin();
    }

    isFormValid() {
        const { form } = this.props.store.modal.loginModal;
        const { email, password } = form;

        const { empty } = new FormValidators();

        return !empty(email) && !empty(password);
    }

    render() {
        const { t } = this.props;
        const { loginModal } = this.props.store.modal;
        const { loginModalPages, errorEnabled, errorMessage, form, loading } = loginModal;
        const { email, password } = form;

        const redirectRegister = () => loginModal.setPage(loginModalPages.signUp);
        const redirectReset = () => loginModal.setPage(loginModalPages.resetPassword);

        const valueChange = fieldName => e => loginModal.setFormValue(fieldName, e.target.value);
        // const valueChangeCheckbox = fieldName => e => loginModal.setFormValue(fieldName, e.target.checked);

        return (
            <form onSubmit={(e) => this.submitForm(e)}>
                <FormGroup>
                    <FormLabel>{t('COMMON.EMAIL')}</FormLabel>
                    <FormControl type="email" value={email}
                                 onChange={valueChange('email')}
                    />
                </FormGroup>

                <FormGroup>
                    <span className="float-right hyperlink" onClick={redirectReset}>
                        {t('COMMON.FORGOT_PASSWORD')}
                    </span>
                    <FormLabel>{t('COMMON.PASSWORD')}</FormLabel>
                    <FormControl type="password" value={password}
                                 onChange={valueChange('password')}
                    />
                </FormGroup>

                {/*
                <div className="form-group">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="rememberMe" checked={rememberMe}
                               onChange={valueChangeCheckbox('rememberMe')}
                        />
                        <label className="custom-control-label pointer" htmlFor="rememberMe">
                            {t('COMMON.REMEMBER_ME')}
                        </label>
                    </div>
                </div>
                */}

                {errorEnabled && <Alert variant="danger" className="text-center">{t(errorMessage)}</Alert>}

                <div className="form-group">
                    <Button variant="primary" className="btn-block" disabled={loading || !this.isFormValid()}
                            type="submit">
                        {t('COMMON.SIGN_IN')}
                        {loading &&
                            <Spinner
                                as="span"
                                animation="border"
                                size="custom"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                    </Button>
                    <Button variant="outline-primary" className="nav-btn btn-block" disabled={loading}
                            onClick={redirectRegister}>
                        {t('COMMON.SIGN_UP')}
                    </Button>
                </div>
            </form>
        );
    }
}

export default LoginModalContent;
