import { action, observable } from 'mobx';
import TimetableApi from 'api/TimetableApi';

class TimetableStore {
    @observable data;
    @observable formData;

    @observable errorEnabled;
    @observable errorMessage;
    @observable successEnabled;
    @observable successMessage;

    @observable loading = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    setData(value) {
        this.data = value;
    }

    @action
    setFormData(value) {
        this.formData = value;
        this.disableAlert();
    }

    @action
    getTimetable() {
        if (!this.data) {
            getTimetablePromise().then((data) => this.setData(data));
        }
    }

    @action
    upload() {
        this.disableAlert();
        this.setLoading(true);
        postTimetablePromise(this.formData, this.rootStore.user.token)
            .then((data) => {
                this.enableSuccessAlert(data)
                this.setData(this.formData);
                this.setLoading(false);
            })
            .catch((message) => {
                this.enableErrorAlert(message)
                this.setLoading(false);
            });
    }

    @action
    delete() {
        this.disableAlert();
        this.setLoading(true);
        postTimetablePromise(null, this.rootStore.user.token)
            .then((data) => {
                this.enableSuccessAlert('COMMON.TIMETABLE_DISABLED')
                this.setData(null);
                this.setLoading(false);
            })
            .catch((message) => {
                this.enableErrorAlert(message)
                this.setLoading(false);
            });
    }

    @action
    enableSuccessAlert(message) {
        this.successMessage = message;
        this.successEnabled = true;
    }

    @action
    enableErrorAlert(message) {
        this.errorMessage = message;
        this.errorEnabled = true;
    }

    @action
    disableAlert() {
        this.errorEnabled = false;
        this.successEnabled = false;
    }

    @action
    setLoading(value) {
        this.loading = value;
    }
}

const getTimetablePromise = () => {
    return new Promise((resolve, reject) => {
        TimetableApi.getTimetable()
            .then(({ data }) => resolve(data))
            .catch(() => reject());
    })
}

const postTimetablePromise = (data, jwt) => {
    return new Promise((resolve, reject) => {
        TimetableApi.postTimetable({ data }, jwt)
            .then(({ data }) => resolve(data))
            .catch(() => reject('COMMON.SERVER_ERROR'));
    })
}

export default TimetableStore;
