import PaymentApi from 'api/PaymentApi';
import { observable } from 'mobx';
import Paginated from 'store/Utils/Paginated';

class PaymentUserListStore extends Paginated {
    @observable getDataPromise;
    @observable sortBy = 'paid';
    @observable sortDir = 'DESC';

    constructor(rootStore) {
        super(rootStore);
        this.getDataPromise = getPaginatedUserPaymentListPromise;
    }
}

const getPaginatedUserPaymentListPromise = (pagination, jwt) => {
    return new Promise((resolve, reject) => {
        PaymentApi.getPaginatedUserPaymentList(pagination, jwt)
            .then(({ data }) => resolve(data))
            .catch(() => reject());
    })
}

export default PaymentUserListStore;
