import { action, observable } from 'mobx';
import WorkoutApi from 'api/WorkoutApi';

class WorkoutFormStore {
    @observable form = {
        title: '',
        url: '',
        description: '',
        isFree: false,
    }

    @observable errorEnabled;
    @observable errorMessage;
    @observable successEnabled;
    @observable successMessage;

    @observable loading = false;
    @observable show = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    setFormValue(fieldName, value) {
        this.form[fieldName] = value;
        this.successEnabled = false;
        this.errorEnabled = false;
    }

    @action
    upload() {
        this.setLoading(true);
        workoutUploadPromise(this.form, this.rootStore.user.token)
            .then(({ data }) => {
                this.resetForm();
                this.enableSuccessAlert(data)
                this.setLoading(false);
                this.toggleModal();
                this.rootStore.workout.adminList.getData();
            })
            .catch((message) => {
                this.enableErrorAlert(message);
                this.setLoading(false);
            })
    }

    @action
    setLoading(value) {
        this.loading = value;
    }

    @action
    enableSuccessAlert(message) {
        this.successMessage = message;
        this.successEnabled = true;
    }

    @action
    enableErrorAlert(message) {
        this.errorMessage = message;
        this.errorEnabled = true;
    }

    @action
    toggleModal() {
        this.resetForm();
        this.show = !this.show;
    }

    @action
    resetForm() {
        this.setFormValue('title', '');
        this.setFormValue('url', '');
        this.setFormValue('description', '');
        this.setFormValue('isFree', false);
        this.successEnabled = false;
        this.errorEnabled = false;
    }
}

const workoutUploadPromise = (body, jwt) => {
    return new Promise((resolve, reject) => {
        WorkoutApi.postWorkoutUpload(body, jwt)
            .then((res) => resolve(res))
            .catch((res) => {
                if (res.response && res.response.data && res.response.data.message) {
                    reject(res.response.data.message)
                } else {
                    reject('COMMON.SERVER_ERROR')
                }
            });
    })
}

export default WorkoutFormStore;
