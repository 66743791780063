import { action, observable } from 'mobx';
import PaymentApi from 'api/PaymentApi';

class PaymentCallbackStore {
    @observable payment;
    @observable status;

    @observable errorEnabled;
    @observable errorMessage;

    @observable loading = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    getPaymentFromCallback(query) {
        this.setLoading(true);
        postPayseraCallbackPromise(query)
            .then((data) => {
                this.setPayment(data.payment);
                this.setStatus(data.status);
                this.setLoading(false);
            })
            .catch((message) => {
                this.enableErrorAlert(message);
                this.setLoading(false);
            })
    }

    @action
    setPayment(value) {
        this.payment = value;
    }

    @action
    setStatus(value) {
        this.status = value;
    }

    @action
    setLoading(value) {
        this.loading = value;
    }

    @action
    enableErrorAlert(message) {
        this.errorMessage = message;
        this.errorEnabled = true;
    }
}

const postPayseraCallbackPromise = (queryParams) => {
    return new Promise((resolve, reject) => {
        PaymentApi.postPayseraCallback(queryParams)
            .then(({ data }) => resolve(data))
            .catch((res) => {
                reject('COMMON.PAYMENT_CALLBACK_ERROR')
            });
    })
}

export default PaymentCallbackStore;
