import React, { Component, Fragment } from 'react';
import {
    Alert,
    Button,
    Col,
    Container,
    FormControl,
    FormGroup,
    FormLabel,
    Modal,
    Row
} from 'react-bootstrap';
import Loader from 'app/components/Loader';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import FormValidators from 'app/components/validators/FormValidators';
import Req from 'app/components/Req';

@translate()
@inject('store')
@observer
class UserAdminAddModal extends Component {
    isFormValid() {
        const { empty, isEmailValid } = new FormValidators();
        const { add } = this.props.store.userAdmin;
        const { firstName, lastName, email } = add.form;

        return !empty(firstName) && !empty(lastName) && (empty(email) || isEmailValid(email));
    }

    render() {
        const { t } = this.props;
        const { add } = this.props.store.userAdmin;
        const { firstName, lastName, email } = add.form;
        const { show, loading, errorEnabled, errorMessage } = add;

        const valueChange = fieldName => e => add.setFormValue(fieldName, e.target.value);

        return (
            <Fragment>
                <Modal size="sm" show={show} onHide={() => add.toggleModal()}>
                    <Modal.Header>
                        <span className="font-weight-bolder">{t('COMMON.ADD_USER')}</span>
                    </Modal.Header>
                    <Modal.Body>
                            <Container>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <FormLabel>{t('COMMON.FIRST_NAME')}<Req /></FormLabel>
                                            <FormControl
                                                type="text"
                                                value={firstName}
                                                onChange={valueChange('firstName')} />
                                        </FormGroup>
                                    </Col>

                                    <Col md={12}>
                                        <FormGroup>
                                            <FormLabel>{t('COMMON.LAST_NAME')}<Req /></FormLabel>
                                            <FormControl
                                                type="text"
                                                onChange={valueChange('lastName')}
                                                value={lastName} />
                                        </FormGroup>
                                    </Col>

                                    <Col md={12}>
                                        <FormGroup>
                                            <FormLabel>{t('COMMON.EMAIL')}</FormLabel>
                                            <FormControl
                                                type="email"
                                                onChange={valueChange('email')}
                                                value={email} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Loader loading={loading} />
                            </Container>
                    </Modal.Body>
                    <Modal.Footer>

                        {errorEnabled &&
                            <Alert variant="warning" className="text-center w-100">{t(errorMessage)}</Alert>
                        }

                        <div className="form-group float-right w-100 text-right">
                            <Button variant="primary" className="mr-2" disabled={!this.isFormValid()}
                                    onClick={() => add.postUser()}>
                                {t('COMMON.SAVE')}
                            </Button>

                            <Button variant="outline-primary"
                                    onClick={() => add.toggleModal()}>
                                {t('COMMON.CLOSE')}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

export default UserAdminAddModal;
