import React, { Component, Fragment } from 'react';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import Loader from 'app/components/Loader';
import { withRouter } from 'react-router-dom';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

@withRouter
@translate()
@inject('store')
@observer
class WorkoutPaidList extends Component {
    componentDidMount() {
        this.props.store.workout.list.getPaidWorkoutList();
    }

    isOlderThanWeek(workoutDate){
        let yesterday = moment().subtract(7, 'd');
        return moment(workoutDate).isBefore(yesterday);
    }

    render() {
        const { t, history }= this.props;
        const { list } = this.props.store.workout;
        const { paidLoading, paidWorkouts } = list;
        const { isOnlineSubscriptionValid } = this.props.store.user;

        return (
            <Col md={12}>
                <Container className="mt-4">
                    <Row>
                        {(paidLoading) ?
                            <div className="vh-55">
                                <Loader loading={paidLoading} />
                            </div>
                            :
                            <Fragment>
                                {(paidWorkouts && paidWorkouts.length > 0) ?
                                    <Fragment>
                                        {paidWorkouts.map((workout, i) => {
                                            return (
                                                <Col xs={12} md={6} lg={4} xl={4} key={i}>
                                                    <div className="card mb-4 box-shadow">
                                                        <div className="card-header">
                                                            <div className="workout-title">
                                                                {workout.title}
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="workout-desc">
                                                                {workout.description}
                                                            </div>
                                                            <div className="flex-centered">
                                                                {isOnlineSubscriptionValid &&
                                                                    <Button size="sm" variant="success"
                                                                            className="btn-block mt-3 workout-watch-btn"
                                                                            onClick={() => history.push(
                                                                                `workout/paid/${workout.id}`
                                                                            )}>
                                                                        {t('COMMON.WATCH')}
                                                                    </Button>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {this.isOlderThanWeek(workout['created']) ?
                                                                <div className="sub-online-item workout-date">
                                                                    {moment(workout['created']).format('YYYY-MM-DD')}
                                                                </div>
                                                                :
                                                                <div className="sub-online-item workout-date new-workout">
                                                                    {moment(workout['created']).format('YYYY-MM-DD')}
                                                                    {' '}
                                                                    <span className="new">
                                                                        {t('COMMON.NEW').toUpperCase()}
                                                                    </span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <div className="w-100 text-center">
                                            <Alert variant="info" className="text-center d-inline-block">
                                                {t('COMMON.WORKOUT_NOT_FOUND')}
                                            </Alert>
                                        </div>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </Row>
                </Container>
            </Col>

        );
    }
}

export default WorkoutPaidList;
