import { action, observable } from 'mobx';

class LandingStore {
    @observable isTimetableOpen = false;
    @observable isAboutUsOpen= false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    toggleTimetable() {
        this.isTimetableOpen = !this.isTimetableOpen;
    }

    @action
    toggleIsAboutUsOpen() {
        this.isAboutUsOpen = !this.isAboutUsOpen;
    }
}

export default LandingStore;
