import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { translate } from 'i18n/i18n';

@translate()
class Health extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="position-relative overflow-hidden text-center landing">
                <Row>
                    <Col md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }} className="policy">
                        <div className="text-center policy-title">{t('COMMON.PERSON_HEALTH_APPROVAL').toUpperCase()}</div>
                        <p>A&scaron; suprantu ir patvirtinu, kad:</p>
                        <ul>
                            <li>Mano sveikatos būklė leidžia sportuoti&nbsp;<strong><em>UŽ 7 MARIŲ</em></strong>&nbsp;sporto judėjimo bendruomenėje pagal tokio pobūdžio auk&scaron;to intensyvumo, taip pat žemo intensyvumo, dinamines ir tempimo treniruočių programas, tiek salėje, tiek internetu.</li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>A&scaron; suprantu, kad prie&scaron; pradėdamas sportuoti ar sportuojant&nbsp;<strong><em>UŽ 7 MARIŲ</em></strong>&nbsp;auk&scaron;to ar žemo intensyvumo, dinaminėse ir tempimo treniruotėse, tiek salėje, tiek internetu, man yra&nbsp;<strong>būtina pasitikrinti sveikatą, pasitarti su gydytoju ir įsitikinti, kad mano sveikatos būklė leidžia treniruotis</strong>.</p>
                        <p><strong>A&scaron; suprantu ir patvirtinu, kad visa atsakomybė dėl galimų mano sveikatos sutrikimų ar nelaimingų atsitikimų, kurie gali kilti sportuojant&nbsp;<em>UŽ 7 MARIŲ</em>, tenka man pačiam.</strong>&nbsp;Dėl &scaron;ios priežasties treneriai, už man atsiradusius sveikatos sutrikimus, galimai sukeltus treniruočių, neatsako.</p>
                        <p><strong>A&scaron; patvirtinu, kad mano duomenys dėl tinkamos sveikatos būklės sportuoti pagal auk&scaron;to</strong>&nbsp;<strong>ar žemo intensyvumo, dinamines ir tempimo treniruočių programas yra teisingi.</strong></p>
                        <p>Kilus įtarimų dėl mano netinkamos sveikatos būklės, papra&scaron;ius treneriams ar gavus atitinkamos sveikatos priežiūros įstaigos pažymą, įrodančią tą faktą, a&scaron; nedelsdamas privalau nutraukti treniruotę, o dėl tolimesnių treniruočių lankymo privalau pasikonsultuoti su gydytoju ir / ar pateikti sportuoti leidžiančią pažymą.</p>
                        <p>A&scaron; patirtinu, kad visų treniruočių metu elgsiuosi atsakingai &ndash; kiekvienos treniruotės metu a&scaron; savaranki&scaron;kai pasirinksiu fizinį krūvį pagal savo individualias fizines savybes ir galimybes.</p>
                        <p>A&scaron; įsipareigoju nedelsiant informuoti trenerius ir / ar kitą atsakingą personalą apie savo ar kitų sportuojančiųjų sveikatos pablogėjimą, patirtą traumą bei apie visus kitus galimus sveikatos sutrikimus.</p>
                        <p>&nbsp;</p>
                        <p><strong>* Treniruotes gali lankyti asmenys, sulaukę 16 metų.&nbsp;</strong>Nepilnametis nuo 16 metų pasira&scaron;o pats kartu su tėvais (globėjais, rūpintojais) arba pateikiamas ra&scaron;ytinis tėvų (globėjų, rūpintojų) sutikimas. Tėvų (globėjų, rūpintojų) para&scaron;as ar sutikimas patvirtina, kad nepilnamečio sveikatos būklė leidžia sportuoti pagal auk&scaron;to intensyvumo treniruočių programas.</p>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Health;
