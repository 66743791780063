import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { translate } from 'i18n/i18n';

@translate()
class LandingSocial extends Component {
    redirectToFB() {
        this.browserRedirectTimeout('https://www.facebook.com/groups/281520739365685');
        window.location = 'fb://group/281520739365685';
    }

    redirectToIG() {
        this.browserRedirectTimeout('https://www.instagram.com/uz7mariu/');
        window.location = 'instagram://user?username=uz7mariu';
    }

    browserRedirectTimeout(url) {
        return setTimeout(function () { window.location = url; }, 25);
    }

    render() {
        const { t } = this.props;
        return (
            <Container>
                <Row>
                    <Col md={6} xs={12}>
                        <div className="pt-4">
                            <i onClick={() => {this.redirectToFB()}}
                               className="fab fa-facebook-square text-white media-icon-fb mb-3">
                            </i>
                            <Col md={{ span: 8, offset: 2 }}>
                                <span className="social-description">{t('COMMON.FACEBOOK_DESCRIPTION')}</span>
                            </Col>
                        </div>
                    </Col>
                    <Col md={6} xs={12}>
                        <div className="pt-4">
                            <i onClick={() => {this.redirectToIG()}}
                               className="fab fa-instagram media-icon-ig mb-3">
                            </i>
                            <Col md={{ span: 8, offset: 2 }}>
                                <span className="social-description">{t('COMMON.INSTAGRAM_DESCRIPTION')}</span>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default LandingSocial;
