import React, { Component, Fragment } from 'react';
import { Col } from 'react-bootstrap';
import moment from 'moment';

class WorkoutView extends Component {
    constructor() {
        super();
        this.myInput = React.createRef();
        this.state = { inputHeight: 0 };
    }

    componentDidMount() {
        this.setState({ inputHeight: this.myInput.current ? ((this.myInput.current.offsetWidth - 30) / 16 * 9) : 0 });
    }

    serializeUrl(workoutUrl) {
        let rx = new RegExp('[?&]v=([^&]+).*$');
        let returnVal = workoutUrl.match(rx);

        if (returnVal && returnVal[1]) {
            return `https://www.youtube.com/embed/${returnVal[1]}?autoplay=1&modestbranding=1`;
        }

        return null;
    }

    render() {
        const { workout } = this.props;

        return (
            <Fragment>
                <Col md={12}>
                    <h4 className="text-center workouts-online-title">
                        {workout.title.toUpperCase()}
                    </h4>
                </Col>

                <hr width="100%" />

                <Col md={12}>
                    <Col lg={{ span: 8, offset: 2 }}
                         md={{ span: 10, offset: 1 }}
                         xs={{ span: 10, offset: 1 }} ref={this.myInput} className="mb-5 mt-5">
                        <div className="text-center">
                            <div className="iframe-box" style={{ height: this.state.inputHeight }}>
                                <iframe className="responsive-iframe"
                                        style={{ height: this.state.inputHeight }}
                                        src={this.serializeUrl(workout.url)}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen;" >
                                </iframe>
                                <div className="iframe-substitute"></div>
                            </div>
                        </div>
                    </Col>
                </Col>

                <hr width="100%" />

                <Col md={12}>
                    <div className="workout-date-created">
                        {moment(workout['created']).format('YYYY-MM-DD')}
                    </div>
                </Col>

                <Col md={12}>
                    <div className="workout-desc-view mt-4">
                        {workout.description}
                    </div>
                </Col>
            </Fragment>
        );
    }
}

export default WorkoutView;
