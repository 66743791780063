import { action, observable } from 'mobx';
import UserApi from 'api/UserApi';

class UserAdminAddStore {
    @observable form = {
        firstName: '',
        lastName: '',
        email: '',
    }

    @observable loading = false;
    @observable show = false;

    @observable errorEnabled;
    @observable errorMessage;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    setValue(name, value) {
        this[name] = value;
    }

    @action
    setFormValue(name, value) {
        this.form[name] = value;
        this.disableErrorAlert();
    }

    @action
    enableErrorAlert(message) {
        this.errorMessage = message;
        this.errorEnabled = true;
    }

    @action
    disableErrorAlert() {
        this.errorEnabled = false;
        this.errorMessage = null;
    }

    @action
    toggleModal() {
        this.resetForm();
        this.show = !this.show;
    }

    @action
    postUser() {
        this.setValue('loading', true);
        postAddUserPromise(this.form, this.rootStore.user.token)
            .then(() => {
                this.rootStore.userAdmin.list.getData();
                this.toggleModal();
                this.setValue('loading', false);
            })
            .catch((message) => {
                this.enableErrorAlert(message);
                this.setValue('loading', false);
            });
    }

    @action
    resetForm() {
        this.disableErrorAlert();
        this.form = {
            firstName: '',
            lastName: '',
            email: '',
        }
    }
}

const postAddUserPromise = (body, jwt) => {
    return new Promise((resolve, reject) => {
        UserApi.postAddUser(body, jwt)
            .then(() => resolve())
            .catch((res) => {
                if (res.response && res.response.data && res.response.data.message) {
                    reject(res.response.data.message)
                } else {
                    reject('COMMON.SERVER_ERROR');
                }
            });
    })
}

export default UserAdminAddStore;
