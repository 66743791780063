import { action, observable } from 'mobx';
import WorkoutApi from 'api/WorkoutApi';

class WorkoutListStore {
    @observable freeWorkouts = [];
    @observable loading = false;

    @observable paidWorkouts = [];
    @observable paidLoading = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    setValue(name, value) {
        this[name] = value;
    }

    @action
    getFreeWorkoutList() {
        this.setValue('loading', true);

        getFreeWorkoutListPromise()
            .then((res) => {
                this.setValue('freeWorkouts', res);
                this.setValue('loading', false);
            })
            .catch(() => {
                setTimeout(() => {
                    this.getFreeWorkoutList();
                }, 2000)
            })
    }

    @action
    getPaidWorkoutList() {
        this.setValue('paidLoading', true);

        getPaidWorkoutListPromise(this.rootStore.user.token)
            .then((res) => {
                this.setValue('paidWorkouts', res['data']);
                this.setValue('paidLoading', false);
            })
            .catch(() => {
                setTimeout(() => {
                    this.getPaidWorkoutList();
                }, 2000)
            })
    }
}

const getFreeWorkoutListPromise = () => {
    return new Promise((resolve, reject) => {
        WorkoutApi.getFreeWorkoutList()
            .then(({ data }) => resolve(data))
            .catch(() => reject());
    })
}

const getPaidWorkoutListPromise = (jwt) => {
    return new Promise((resolve, reject) => {
        WorkoutApi.getPaidWorkoutList(jwt)
            .then(({ data }) => resolve(data))
            .catch(() => reject());
    })
}

export default WorkoutListStore;
