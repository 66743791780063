import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import LoginModalContent from 'app/components/modals/login/LoginModalContent';
import RegisterModalContent from 'app/components/modals/login/RegisterModalContent';
import logo from 'assets/img/logo.png';
import { translate } from 'i18n/i18n';
import ResetPasswordModalContent from 'app/components/modals/login/ResetPasswordModalContent';

@translate()
@inject('store')
@observer
class LoginModal extends Component {
    render() {
        const { t } = this.props;
        const { loginModal } = this.props.store.modal;
        const { page, loginModalPages } = loginModal;
        const handleClose = () => loginModal.setShow(false);
        const { show } = loginModal;

        return (
            <Modal size="md" show={show} onHide={handleClose}>
                <Modal.Body className="login-modal">
                    <img className="mr-auto ml-auto" src={logo} alt="login-logo" width="80" height="80" />
                    <h4 className="card-title mb-13 mt-1 mb-1 text-center">{t(`COMMON.${page}`)}</h4>

                    {page === loginModalPages.signIn &&
                        <LoginModalContent />
                    }

                    {page === loginModalPages.signUp &&
                        <RegisterModalContent />
                    }

                    {page === loginModalPages.resetPassword &&
                        <ResetPasswordModalContent />
                    }

                </Modal.Body>
            </Modal>
        );
    }
}

export default LoginModal;
