import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Alert, Button, Form, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap';
import FormValidators from 'app/components/validators/FormValidators';
import Req from 'app/components/Req';

@translate()
@withRouter
@inject('store')
@observer
class EmailResetForm extends Component {
    submitForm(e) {
        const jwt = this.props.store.user.token;
        e.preventDefault();

        this.props.store.email.postChangeEmailAuthenticated(jwt);
    }

    checkValidity(fieldName) {
        const { form } = this.props.store.email;
        const { email, repeatEmail } = form;
        const { empty, isEmailValid } = new FormValidators();

        if (!empty(email) && fieldName === 'email' && !isEmailValid(email)) {
            this.props.store.email.setInvalidFields('email');
        }

        if (!empty(repeatEmail) && repeatEmail !== email) {
            this.props.store.email.setInvalidFields('repeatEmail');
        }

        // This needed to trigger rerender
        this.setState({ test: '' });
    }

    isFormValid() {
        const { form } = this.props.store.email;
        const { email, repeatEmail } = form;

        const { isEmailValid } = new FormValidators();

        return isEmailValid(email) && (email === repeatEmail);
    }

    valueChange(fieldName, value) {
        const { email, repeatEmail } = this.props.store.email.form;

        const { isEmailValid } = new FormValidators();
        const validateEmail = () => this.props.store.email.removeInvalidField('email');
        const validateRepeatEmail = () => this.props.store.email.removeInvalidField('repeatEmail');

        if (fieldName === 'email') {
            if (isEmailValid(value)) {
                validateEmail();
            }

            if (repeatEmail === value) {
                validateRepeatEmail();
            }
        }

        if (fieldName === 'repeatEmail') {
            if (value === email) {
                validateRepeatEmail();
            }
        }

        this.props.store.email.setFormValue(fieldName, value);
    }

    render() {
        const { t } = this.props;
        const {
            invalidFields, form, loading, successEnabled, successMessage, errorEnabled, errorMessage
        } = this.props.store.email;
        const { email, repeatEmail } = form;

        const valueChange = fieldName => e => this.valueChange(fieldName, e.target.value)

        return (
            <Form onSubmit={(e) => this.submitForm(e)}>
                <Form.Group>
                    <span className="tab-email-change">{t('COMMON.CHANGE_EMAIL')}</span>
                </Form.Group>

                <FormGroup>
                    <FormLabel>{t('COMMON.NEW_EMAIL')}<Req /></FormLabel>
                    <FormControl type="email" autoComplete="new-password" value={email}
                                 onChange={valueChange('email')}
                                 className={`${invalidFields['email'] && 'is-invalid'}`}
                                 onBlur={() => this.checkValidity('email')}
                    />
                    <div className="invalid-feedback">{t('COMMON.EMAIL_MUST_BE_VALID')}</div>
                </FormGroup>
                <FormGroup>
                    <FormLabel>{t('COMMON.REPEAT_NEW_EMAIL')}<Req /></FormLabel>
                    <FormControl type="email" autoComplete="new-password" value={repeatEmail}
                                 onChange={valueChange('repeatEmail')}
                                 className={`${invalidFields['repeatEmail'] && 'is-invalid'}`}
                                 onBlur={() => this.checkValidity('repeatEmail')}
                    />
                    <div className="invalid-feedback">{t('COMMON.EMAILS_MUST_MATCH')}</div>
                </FormGroup>

                {successEnabled &&
                    <Alert variant="success" className="text-center">{t(successMessage)}</Alert>
                }

                {errorEnabled &&
                    <Alert variant="warning" className="text-center">{t(errorMessage)}</Alert>
                }

                <div className="form-group">
                    <Button variant="primary" className="btn-block" type="submit"
                            disabled={loading || !this.isFormValid()}
                            onClick={() => {}}>
                        {t('COMMON.CHANGE_EMAIL')}
                        {loading && !successEnabled &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="custom"
                            role="status"
                            aria-hidden="true"
                        />
                        }
                    </Button>
                </div>
            </Form>
        )
    }
}

export default EmailResetForm;
