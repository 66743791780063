import Api from 'api/api';

class AuthenticationApi {
    postRegistration(body) {
        return Api.post('/auth/register', body);
    }

    postLogin(body) {
        return Api.post('/auth/login', body);
    }
}

export default new AuthenticationApi();
