import React, { Fragment } from 'react';
import Layout from 'app/layout/Layout.jsx';

function App() {
    return (
        <Fragment>
            <Layout />
        </Fragment>
    );
}

export default App;
