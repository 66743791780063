import { action, observable } from 'mobx';
import WorkoutApi from 'api/WorkoutApi';

class WorkoutPaidWatch {
    @observable workout;
    @observable loading = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    setValue(name, value) {
        this[name] = value;
    }

    @action
    getPaidWorkoutById(id) {
        this.setValue('loading', true);
        this.setValue('workout', null);

        getPaidWorkoutById(id, this.rootStore.user.token)
            .then((res) => {
                this.setValue('workout', res);
                this.setValue('loading', false);
            })
            .catch(() => {
                window.location.href = '/';
            })
    }
}

const getPaidWorkoutById = (id, jwt) => {
    return new Promise((resolve, reject) => {
        WorkoutApi.getPaidWorkoutById(id, jwt)
            .then(({ data }) => resolve(data))
            .catch(() => reject());
    })
}

export default WorkoutPaidWatch;
