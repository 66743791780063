import { action, computed, observable } from 'mobx';

class Paginated {
    @observable loading = false;

    @observable data;
    @observable getDataPromise;

    @observable page = 0;
    @observable pageSize = 10;
    @observable sortBy = '';
    @observable sortDir = '';
    @observable search = '';

    @observable resetPaginationToggle = false;

    @observable total = 0;
    @observable totalPages = 0;

    @observable debounce = false;

    constructor(rootStore) {
        this.rootStore = rootStore
    }

    @action
    setValue(name, value) {
        this[name] = value;
    }

    @action
    setSearch(value) {
        this.setValue('search', value);

        this.setValue('debounce', true);
        this.onSearchDebounce(() => {
            this.setValue('page', 0);
            this.setValue('resetPaginationToggle', true);
            this.setValue('resetPaginationToggle', false);
            this.getData();
            this.setValue('debounce', false);
        }, 1000);
    }

    @action
    getData() {
        this.setValue('loading', true);
        this.getDataPromise(this.pagination, this.rootStore.user.token)
            .then((data) => {
                this.setData(data);
                this.setValue('loading', false);
            })
            .catch(() => {
                setTimeout(() => this.getData(), 1500)
            })
    }

    @action
    onPageChange(newPage) {
        this.setValue('page', newPage);
        this.getData();
    }

    @action
    onSortChange(newSort, dir) {
        this.setValue('sortBy', newSort);
        this.setValue('sortDir', dir.toUpperCase());
        this.getData();
    }

    @action
    setData(data) {
        this.setValue('data', data.data);
        this.setValue('page', data.page);
        this.setValue('total', data.total);
        this.setValue('totalPages', data.totalPages);
    }

    @action
    onSearchDebounce(func, delay) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            func();
        }, delay);
    }

    @computed
    get pagination() {
        let params =  {
            page: this.page,
            pageSize: this.pageSize
        };

        if (this.search !== '') {
            params['search'] = this.search;
        }

        if (this.sortBy !== '') {
            params['sortBy'] = this.sortBy;
        }

        if (this.sortDir !== '') {
            params['sortDir'] = this.sortDir;
        }

        return Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }
}

export default Paginated;
