import PaymentFormStore from 'store/PaymentStore/PaymentFormStore';
import PaymentCallbackStore from 'store/PaymentStore/PaymentCallbackStore';
import PaymentUserListStore from 'store/PaymentStore/PaymentUserListStore';
import PaymentAdminListStore from 'store/PaymentStore/PaymentAdminListStore';

class PaymentStore {
    constructor(rootStore) {
        this.form = new PaymentFormStore(rootStore);
        this.callback = new PaymentCallbackStore(rootStore);
        this.user = new PaymentUserListStore(rootStore);
        this.admin = new PaymentAdminListStore(rootStore);
    }
}

export default PaymentStore;
