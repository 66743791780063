import React, { Component, Fragment } from 'react';
import {
    Alert,
    Button,
    Container,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    Modal,
    Spinner
} from 'react-bootstrap';
import Req from 'app/components/Req';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import FormValidators from 'app/components/validators/FormValidators';

@translate()
@inject('store')
@observer
class WorkoutAddModal extends Component {
    componentDidMount() {
        this.props.store.workout.form.resetForm();
    }

    submitForm(e) {
        e.preventDefault();
        this.props.store.workout.form.upload();
    }

    valueChange(fieldName, value) {
        this.props.store.workout.form.setFormValue(fieldName, value);
    }

    isFormValid() {
        const { title, url, description } = this.props.store.workout.form.form;

        const { empty } = new FormValidators();

        return !empty(title) && !empty(url) && !empty(description);
    }

    render() {
        const { t } = this.props;
        const {
            form: { title, url, description, isFree },
            loading, errorEnabled, errorMessage, successEnabled, successMessage, show
        } = this.props.store.workout.form;
        const valueChange = fieldName => e => this.valueChange(fieldName, e.target.value)

        return (
            <Fragment>
                <Container>
                    <Modal size="md" show={show} onHide={() => this.props.store.workout.form.toggleModal()}>
                        <Form onSubmit={(e) => this.submitForm(e)}>
                            <Modal.Header>
                                <span className="font-weight-bolder">{t('COMMON.UPLOAD_WORKOUT')}</span>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup>
                                    <FormLabel>{t('COMMON.TITLE')}<Req /></FormLabel>
                                    <FormControl type="text" value={title} onChange={valueChange('title')} />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>{t('COMMON.LINK')}<Req /></FormLabel>
                                    <FormControl type="text" value={url} onChange={valueChange('url')} />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>{t('COMMON.DESCRIPTION')}<Req /></FormLabel>
                                    <FormControl as="textarea" rows={3} value={description}
                                                 onChange={valueChange('description')} />
                                </FormGroup>

                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="isFreeWorkout"
                                               checked={isFree}
                                               onChange={() => this.valueChange('isFree', !isFree)}
                                        />
                                        <label className="custom-control-label pointer" htmlFor="isFreeWorkout">
                                            {t('COMMON.IS_WORKOUT_FREE')}
                                        </label>
                                    </div>
                                </div>

                                {successEnabled &&
                                <Alert variant="success" className="text-center">{t(successMessage)}</Alert>
                                }

                                {errorEnabled &&
                                <Alert variant="warning" className="text-center">{t(errorMessage)}</Alert>
                                }

                                <div className="form-group">
                                    <Button variant="primary" className="btn-block" type="submit"
                                            disabled={loading || !this.isFormValid()}
                                            onClick={() => {}}>
                                        {t('COMMON.UPLOAD')}
                                        {loading &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="custom"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        }
                                    </Button>
                                </div>
                            </Modal.Body>
                        </Form>
                    </Modal>
                </Container>
            </Fragment>
        );
    }
}

export default WorkoutAddModal;
