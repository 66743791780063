import { action, computed, observable } from 'mobx';
import AuthenticationApi from 'api/AuthenticationApi';
import PublicUserApi from 'api/PublicUserApi';
import UserApi from 'api/UserApi';
import moment from 'moment';

class UserStore {
    @observable token;
    @observable id;
    @observable firstName;
    @observable lastName;
    @observable email;
    @observable subscription;
    @observable subscriptionOnline;

    @observable roles = [];

    @observable isCompany;
    @observable companyName;
    @observable companyCode;
    @observable companyAddress;

    @observable userLoading = true;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    jwtAuthentication() {
        const jwt = this.getToken();

        if (jwt) {
            this.rootStore.loader.enable();

            UserApi.getUser(jwt)
                .then(({ data }) => {
                    this.rootStore.loader.disable();
                    this.authenticateUser(data);
                    this.setUserLoading(false);
                })
                .catch(() => {
                    this.rootStore.loader.disable();
                    this.setUserLoading(false);
                })
        } else {
            this.setUserLoading(false);
        }
    }

    @action
    authenticateUser(data) {
        const { token, id, email, firstName, lastName, roles, subscription, subscriptionOnline,
            isCompany, companyName, companyCode, companyAddress } = data;

        this.token = token;
        this.id = id;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.roles = roles;
        this.subscription = subscription;
        this.subscriptionOnline = subscriptionOnline;

        this.isCompany = isCompany;
        this.companyName = companyName;
        this.companyCode = companyCode;
        this.companyAddress = companyAddress;

        this.saveToken(token);
    }

    @action
    postRegistration(body) {
        return new Promise((resolve, reject) => {
            registrationPromise(body)
                .then((data) => {
                    this.saveToken(data.token);
                    resolve(data.message);
                })
                .catch((message) => reject(message))
        });
    }

    @action
    postLogin(body) {
        return new Promise((resolve, reject) => {
            loginPromise(body)
                .then((data) => {
                    this.saveToken(data.token);
                    resolve();
                })
                .catch((message) => reject(message))
        });
    }

    @action
    postPasswordReset(body) {
        return new Promise((resolve, reject) => {
            passwordResetPromise(body)
                .then((data) => resolve(data.message))
                .catch((message) => reject(message))
        });
    }

    @action
    logout() {
        this.removeToken();
        window.location.href = '/';
    }

    @action
    saveToken(token) {
        localStorage.setItem('jwt', token);
    }

    @action
    removeToken() {
        localStorage.removeItem('jwt');
    }

    @action
    getToken() {
        return localStorage.getItem('jwt');
    }

    @action
    setUserLoading(value) {
        this.userLoading = value;
    }

    @computed
    get loggedIn() {
        return !!this.id;
    }

    @computed
    get isSubscriptionValid() {
        if (this.subscription) {
            return moment(this.subscription).startOf('day').diff(moment().startOf('day'), 'days') >= 0;
        }

        return false;
    }

    @computed
    get isOnlineSubscriptionValid() {
        if (this.subscriptionOnline) {
            return moment(this.subscriptionOnline).diff(moment().utc(true)) >= 0;
        }

        return false;
    }

    @computed
    get subscriptionDaysLeft() {
        if (this.subscription) {
            return moment(this.subscription).diff(moment().startOf('day'), 'days');
        }

        return 0;
    }

    @computed
    get subscriptionOnlineDaysLeft() {
        if (this.subscriptionOnline) {
            return moment(this.subscriptionOnline).diff(moment().startOf('day'), 'days');
        }

        return 0;
    }

    @computed
    get isAdmin() {
        return this.roles.indexOf('ROLE_ADMIN') >= 0;
    }
}

const registrationPromise = (body) => {
    return new Promise((resolve, reject) => {
        AuthenticationApi.postRegistration(body)
            .then((res) => resolve(res.data))
            .catch((res) => {
                if (res.response && res.response.data && res.response.data.message) {
                    reject(res.response.data.message)
                } else {
                    reject('COMMON.SERVER_ERROR')
                }
            });
    })
}

const loginPromise = (body) => {
    return new Promise((resolve, reject) => {
        AuthenticationApi.postLogin(body)
            .then((res) => resolve(res.data))
            .catch((res) => {
                if (res.response && res.response.status &&
                    (res.response.status === 400 || res.response.status === 401)) {
                    reject('COMMON.RESPONSE.USER_NOT_FOUND')
                } else {
                    reject('COMMON.SERVER_ERROR')
                }
            });
    })
}

const passwordResetPromise = (body) => {
    return new Promise((resolve, reject) => {
        PublicUserApi.postResetPassword(body)
            .then((res) => resolve(res.data))
            .catch((res) => {
                if (res.response && res.response.data && res.response.data.message) {
                    reject(res.response.data.message)
                } else {
                    reject('COMMON.SERVER_ERROR')
                }
            });
    })
}

export default UserStore;
