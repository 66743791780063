import PaymentApi from 'api/PaymentApi';
import { observable } from 'mobx';
import Paginated from 'store/Utils/Paginated';

class PaymentAdminListStore extends Paginated {
    @observable getDataPromise;
    @observable sortBy = 'created';
    @observable sortDir = 'DESC';

    constructor(rootStore) {
        super(rootStore);
        this.getDataPromise = getPaginatedAdminPaymentListPromise
    }
}

const getPaginatedAdminPaymentListPromise = (pagination, jwt) => {
    return new Promise((resolve, reject) => {
        PaymentApi.getPaginatedAdminPaymentList(pagination, jwt)
            .then(({ data }) => resolve(data))
            .catch(() => reject());
    })
}

export default PaymentAdminListStore;
