import React, { Component, Fragment } from 'react';
import { Col } from 'react-bootstrap';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';

@translate()
@inject('store')
@observer
class LandingTimetable extends Component {
    componentDidMount() {
        this.props.store.timetable.getTimetable();
    }

    toggle() {
        const { store: { landing, timetable: { data } } } = this.props;

        if (data) {
            landing.toggleTimetable();
        }
    }

    render() {
        const { t, store: { landing: { isTimetableOpen }, timetable } } = this.props;
        const { data } = timetable;

        return (
            <Col xs={10} md={10} lg={8} xl={8}
                 className="bg-dark box-shadow mx-auto box1 pt-0 pb-3 pr-0 pl-0 text-center">
                {(data) &&
                    <Fragment>
                        <div style={{ backgroundImage: `url(${data})` }}
                             className={`timetable-background ${isTimetableOpen ? 'timetable-background-open' : ''}`}>
                            <img src={data} alt={'timetable'}
                                 className={`timetable-photo ${!isTimetableOpen ? 'timetable-photo-closed' : ''}`}
                            />
                        </div>
                    </Fragment>
                }

                    <Fragment>
                        <div className={`timetable ${data && 'pointer'}`} onClick={() => this.toggle()}>
                            {(data) ?
                                <Fragment>
                                    <i className={`fas fa-angle-down t-arrow ${isTimetableOpen ? 't-arrow-r' : ''}`}>
                                    </i>
                                        {t('COMMON.TIMETABLE').toUpperCase()}
                                    <i className={`fas fa-angle-down t-arrow ${isTimetableOpen ? 't-arrow-f' : ''}`}>
                                    </i>
                                </Fragment>
                                :
                                <div className="pt-4">{' '}</div>
                            }
                        </div>
                    </Fragment>
            </Col>
        )
    }
}

export default LandingTimetable;
