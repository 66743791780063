import UserAdminListStore from 'store/UserStore/UserAdminListStore';
import UserAdminEditStore from 'store/UserStore/UserAdminEditStore';
import UserAdminAddStore from 'store/UserStore/UserAdminAddStore';

class UserAdminStore {
    constructor(rootStore) {
        this.list = new UserAdminListStore(rootStore);
        this.edit = new UserAdminEditStore(rootStore);
        this.add = new UserAdminAddStore(rootStore);
    }
}

export default UserAdminStore;
