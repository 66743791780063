import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import { Col, Container } from 'react-bootstrap';
import PasswordResetForm from 'app/modules/user/components/PasswordResetForm';
import EmailResetForm from 'app/modules/user/components/EmailResetForm';

@translate()
@inject('store')
@observer
class InformationTab extends Component {
    componentDidMount() {
        this.props.store.password.resetForm();
        this.props.store.email.resetForm();
    }

    render() {
        const { firstName, lastName, email } = this.props.store.user;

        return (
            <div className="user-info-tab">
                <Col xs={12} lg={12} className="info-tab-form tab-user-name text-center">
                    <span>{firstName} {lastName}</span>
                    <div className="tab-email">{email}</div>
                </Col>

                <Container>
                    <div className="row">
                        <Col xs={12} md={5} className="info-tab-form tab-user-email">
                            <EmailResetForm />
                            <hr className="d-md-none d-sm-block user-tab-hr" />
                        </Col>

                        <Col xs={12} md={{ span: 5, offset: 2 }} className="info-tab-form tab-user-email">
                            <PasswordResetForm />
                        </Col>
                    </div>
                </Container>
            </div>
        );
    }
}

export default InformationTab;
