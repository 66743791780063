import { action, observable } from 'mobx';
import ProductApi from 'api/ProductApi';

class ProductStore {
    @observable products = [];
    @observable loading = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    getProducts() {
        this.setLoading(true);

        getProductsPromise()
            .then((res) => {
                this.setProducts(res);
                this.setLoading(false);
            })
            .catch(() => {
                setTimeout(() => {
                    this.getProducts();
                }, 2000)
            })
    }

    @action
    setLoading(value) {
        this.loading = value;
    }

    @action
    setProducts(value) {
        this.products = value;
    }
}

const getProductsPromise = () => {
    return new Promise((resolve, reject) => {
        ProductApi.getProducts()
            .then((res) => resolve(res.data))
            .catch(() => reject());
    })
}

export default ProductStore;
