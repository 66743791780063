import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react';
import { Spinner } from 'react-bootstrap';

@inject('store')
@observer
class Loader extends React.Component {
    render() {
        const { loading } = this.props;

        if (!loading) {return null}

        return (
            <div className="loader-box">
                <Spinner animation="border" size="lg" role="status" variant="primary" />
            </div>
        )
    }
}

Loader.propTypes = {
    loading: PropTypes.bool,
}

export default Loader
