import React, { Component } from 'react';
import { Container, Row, Badge } from 'react-bootstrap';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Pagination from 'app/components/pagination/Pagination';

@translate()
@inject('store')
@observer
class UserPaymentListTab extends Component {
    render() {
        const { t } = this.props;

        const columns = [
            {
                name: t('COMMON.TITLE'),
                selector: 'title',
                sortable: true,
                minWidth: '200px',
            },
            {
                name: t('COMMON.ORDER_ID_SHORT'),
                selector: 'orderId',
                sortable: true,
                center: true,
                minWidth: '130px',
                maxWidth: '150px',
            },
            {
                name: t('COMMON.DATE'),
                selector: 'paid',
                format: (row) => {return moment(row['paid']).format('YYYY-MM-DD HH:mm')},
                sortable: true,
                center: true,
                minWidth: '140px',
                maxWidth: '160px',
            },
            {
                name: t('COMMON.STATUS'),
                selector: () => {return <Badge variant="success">{t('COMMON.PAID')}</Badge>},
                center: true,
                minWidth: '100px',
                maxWidth: '130px',
                style: { fontSize: '1rem' }
            },
            {
                name: t('COMMON.TOTAL_PRICE'),
                selector: 'amount',
                format: (row) => {return row.amount.toFixed(2) + ' ' + row.currency},
                sortable: true,
                right: true,
                minWidth: '80px',
                maxWidth: '100px',
            }
        ];

        return (
            <Container>
                <Row>
                    <Pagination paginatedStore={this.props.store.payment.user} columns={columns} />
                </Row>
            </Container>
        );
    }
}

export default UserPaymentListTab;
