import { observable } from 'mobx';
import Paginated from 'store/Utils/Paginated';
import UserApi from 'api/UserApi';

class UserAdminListStore extends Paginated {
    @observable getDataPromise;
    @observable sortBy = 'subscription';
    @observable sortDir = 'DESC';
    @observable pageSize = 15;

    constructor(rootStore) {
        super(rootStore);
        this.getDataPromise = getPaginatedAdminPaymentListPromise;
    }
}

const getPaginatedAdminPaymentListPromise = (pagination, jwt) => {
    return new Promise((resolve, reject) => {
        UserApi.getPaginatedAdminUserList(pagination, jwt)
            .then(({ data }) => resolve(data))
            .catch(() => reject());
    })
}

export default UserAdminListStore;
