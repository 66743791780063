import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Alert, Button, Form, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap';
import FormValidators from 'app/components/validators/FormValidators';
import Req from 'app/components/Req';

@translate()
@withRouter
@inject('store')
@observer
class PasswordResetForm extends Component {
    submitForm(e) {
        const jwt = this.props.store.user.token;
        e.preventDefault();

        this.props.store.password.postChangePasswordAuthenticated(jwt);
    }

    checkValidity(fieldName) {
        const { password: { form } } = this.props.store;
        const { repeatPassword, password } = form
        const { empty, isPasswordValid } = new FormValidators();

        if (!empty(password) && fieldName === 'password' && !isPasswordValid(password)) {
            this.props.store.password.setInvalidFields('password');
        }

        if (!empty(repeatPassword) && repeatPassword !== password) {
            this.props.store.password.setInvalidFields('repeatPassword');
        }

        // This needed to trigger rerender
        this.setState({ test: '' });
    }

    isFormValid() {
        const { form } = this.props.store.password;
        const { password, repeatPassword } = form;

        const { isPasswordValid } = new FormValidators();

        return isPasswordValid(password) && (password === repeatPassword);
    }

    valueChange(fieldName, value) {
        const { password, repeatPassword } = this.props.store.password.form;

        const { isPasswordValid } = new FormValidators();
        const validatePassword = () => this.props.store.password.removeInvalidField('password');
        const validateRepeatPassword = () => this.props.store.password.removeInvalidField('repeatPassword');

        if (fieldName === 'password') {
            if (isPasswordValid(value)) {
                validatePassword();
            }

            if (repeatPassword === value) {
                validateRepeatPassword();
            }
        }

        if (fieldName === 'repeatPassword') {
            if (value === password) {
                validateRepeatPassword();
            }
        }

        this.props.store.password.setFormValue(fieldName, value);
    }

    render() {
        const { t } = this.props;
        const {
            invalidFields, form, loading, successEnabled, successMessage, errorEnabled, errorMessage
        } = this.props.store.password;
        const { password, repeatPassword } = form;

        const valueChange = fieldName => e => this.valueChange(fieldName, e.target.value)

        return (
            <Form onSubmit={(e) => this.submitForm(e)} autoComplete="new-password">
                <Form.Group>
                    <span className="tab-email-change">{t('COMMON.CHANGE_PASSWORD')}</span>
                </Form.Group>

                <FormGroup>
                    <FormLabel>{t('COMMON.NEW_PASSWORD')}<Req /></FormLabel>
                    <FormControl type="password" autoComplete="new-password" value={password}
                                 onChange={valueChange('password')}
                                 className={`${invalidFields['password'] && 'is-invalid'}`}
                                 onBlur={() => this.checkValidity('password')}
                    />
                    <div className="invalid-feedback">{t('COMMON.PASSWORD_TOO_SHORT')}</div>
                </FormGroup>
                <FormGroup>
                    <FormLabel>{t('COMMON.REPEAT_PASSWORD')}<Req /></FormLabel>
                    <FormControl type="password" autoComplete="new-password" value={repeatPassword}
                                 onChange={valueChange('repeatPassword')}
                                 className={`${invalidFields['repeatPassword'] && 'is-invalid'}`}
                                 onBlur={() => this.checkValidity('repeatPassword')}
                    />
                    <div className="invalid-feedback">{t('COMMON.PASSWORDS_MUST_MATCH')}</div>
                </FormGroup>

                {successEnabled &&
                    <Alert variant="success" className="text-center">{t(successMessage)}</Alert>
                }

                {errorEnabled &&
                    <Alert variant="warning" className="text-center">{t(errorMessage)}</Alert>
                }

                <div className="form-group">
                    <Button variant="primary" className="btn-block" type="submit"
                            disabled={loading || !this.isFormValid()}
                            onClick={() => {}}>
                        {t('COMMON.CHANGE_PASSWORD')}
                        {loading && !successEnabled &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="custom"
                            role="status"
                            aria-hidden="true"
                        />
                        }
                    </Button>
                </div>
            </Form>
        )
    }
}

export default PasswordResetForm;
