import { observable } from 'mobx';
import Paginated from 'store/Utils/Paginated';
import WorkoutApi from 'api/WorkoutApi';

class WorkoutAdminListStore extends Paginated {
    @observable getDataPromise;
    @observable pageSize = 15;

    constructor(rootStore) {
        super(rootStore);
        this.getDataPromise = getPaginatedUserPaymentListPromise;
    }
}

const getPaginatedUserPaymentListPromise = (pagination, jwt) => {
    return new Promise((resolve, reject) => {
        WorkoutApi.getPaginatedAdminWorkoutList(pagination, jwt)
            .then(({ data }) => resolve(data))
            .catch(() => reject());
    })
}

export default WorkoutAdminListStore;
