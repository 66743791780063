import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'i18n/i18n';
import { Badge } from 'react-bootstrap';

@translate()
class ProductTypeCell extends Component {
    render() {
        const { t, row } = this.props;
        const { isCreditSubscription, isOnlineSubscription, isSubscriptionFull } = row;

        if (isOnlineSubscription) {
            return (
                <div>
                    <Badge variant="success" className="badge-online">{t('COMMON.ONLINE')}</Badge>
                </div>
            );
        } else if (isSubscriptionFull) {
            return (
                <div>
                    <Badge variant="success" className="badge-full">{t('COMMON.SUBSCRIPTION_IS_FULL_TYPE')}</Badge>
                </div>
            );
        } else {
            return (
                <div>
                    <Badge variant="success" className="badge-hall">{t('COMMON.HALL')}</Badge>
                    {isCreditSubscription && <Badge variant="dark" className="ml-1">
                        {t('COMMON.TIMES')}
                    </Badge>}
                </div>
            );
        }


    }
}

ProductTypeCell.propTypes = {
    row: PropTypes.any,
}

export default ProductTypeCell;
