import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import PropTypes from 'prop-types';
import Loader from 'app/components/Loader';
import { toJS } from 'mobx';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import { Button, Col, Form, Row } from 'react-bootstrap';

@translate()
@inject('store')
@observer
class Pagination extends Component {
    componentDidMount() {
        this.props.paginatedStore.getData();
    }

    render() {
        const { t, withSearch, addBtn, onAdd, addTitle } = this.props;
        const store = this.props.paginatedStore;
        const { pageSize, total, loading, sortBy, search, resetPaginationToggle } = store;
        const data = toJS(store.data);

        const paginationOptions = {
            rangeSeparatorText: t('COMMON.FROM'),
            noRowsPerPage: true,
            selectAllRowsItem: false,
        }

        return (
            <Fragment>

                <Col md={12}>
                    <Row>
                        {withSearch &&
                            <Col xs={7}>
                                <Form.Group>
                                    <input
                                        type="text"
                                        placeholder={t('COMMON.SEARCH')}
                                        className="search-input form-control"
                                        value={search}
                                        onChange={(e) => store.setSearch(e.target.value)}/>
                                </Form.Group>
                            </Col>
                        }
                        {addBtn &&
                            <Col className="flex-je-ac mt-3 mb-2">
                                <div>
                                    <Button variant="outline-primary" className="mr-2" onClick={() => onAdd()}>
                                        {addTitle}
                                    </Button>
                                </div>
                            </Col>
                        }
                    </Row>
                </Col>

                <Col className="pb-2">
                    <Fragment>
                        <DataTable
                            {...this.props}
                            noHeader
                            pagination={total > pageSize}
                            data={data}
                            customStyles={customStyles}
                            striped
                            paginationResetDefaultPage={resetPaginationToggle}
                            highlightOnHover
                            paginationComponentOptions={paginationOptions}
                            paginationServer
                            sortServer
                            noDataComponent={noDataComponent(t('COMMON.NO_RECORDS'))}
                            paginationTotalRows={total}
                            paginationPerPage={pageSize}
                            defaultSortField={sortBy}
                            onSort={({ selector }, dir) => store.onSortChange(selector, dir)}
                            onChangePage={(page) => store.onPageChange(page - 1)}
                        />
                        <Loader loading={loading} />
                    </Fragment>
                </Col>
            </Fragment>
        );
    }
}

const customStyles = {
    headCells: {
        style: {
            fontSize: '0.9rem',
            fontWeight: 700,
            paddingLeft: '2px',
            paddingRight: '2px',
        },
    },
    headRow: {
        style: {
            minHeight: '40px',
            borderBottomColor: 'rgba(0,0,0,.7)',
            marginTop: '.5rem'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px',
            paddingRight: '2px',
            fontSize: '14px'
        },
    },
};

Pagination.propTypes = {
    paginatedStore: PropTypes.any.isRequired,
    columns: PropTypes.any.isRequired,
    withSearch: PropTypes.any,
    addBtn: PropTypes.any,
    addTitle: PropTypes.any,
    onAdd: PropTypes.any,
}

const noDataComponent = (title) => {
    return (
        <div className="no-data-display">
            <span>{title}</span>
        </div>
    )
}

export default Pagination;
