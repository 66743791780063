import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';

@translate()
@inject('store')
@observer
class LandingAboutUs extends Component {
    render() {
        const { t, store: { landing: { isAboutUsOpen }, landing } } = this.props;

        return <Container>
            <Row id="module" >
                <Col md={12}>
                    <div className="about-us-title">{t('COMMON.ABOUT_US').toUpperCase()}</div>
                </Col>
                <Col className={`about-us-text ${!isAboutUsOpen ? 'about-us-overflow' : ''}`}
                     sm={12} md={12} lg={12}>
                    <p>
                        {t('COMMON.ABOUT_US_INFO')}
                    </p>
                </Col>
                <Col sm={12} md={12} lg={12}>
                    <div className={`read-more ${isAboutUsOpen ? 'hidden' : ''}`}
                         onClick={() => landing.toggleIsAboutUsOpen()}>
                        {t('COMMON.READ_MORE')}...
                    </div>
                </Col>
            </Row>
        </Container>
    }
}

export default LandingAboutUs;
