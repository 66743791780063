import { action, observable } from 'mobx';
import PublicUserApi from 'api/PublicUserApi';
import UserApi from 'api/UserApi';

class UserPasswordResetStore {
    @observable form = {
        password: '',
        repeatPassword: ''
    }

    @observable invalidFields = {};

    @observable isTokenExpired = false;

    @observable loading = false;

    @observable errorEnabled;
    @observable errorMessage;
    @observable successEnabled;
    @observable successMessage;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    validateJwt(jwt) {
        this.setLoading(true);
        validateJwtPromise(jwt)
            .then(() => this.setLoading(false))
            .catch((message) => {
                this.enableErrorAlert(message);
                this.setIsTokenExpired(true);
                this.setLoading(false);
            })
    }

    @action
    postChangePassword(jwt) {
        this.setLoading(true);
        postChangePasswordPromise(this.form, jwt)
            .then((data) => {
                this.enableSuccessAlert(data.message)
                setTimeout(() => this.redirectToFrontPage(), 4000);
            })
            .catch((message) => {
                this.enableErrorAlert(message);
                this.setLoading(false);
            })
    }

    @action
    postChangePasswordAuthenticated(jwt) {
        this.setLoading(true);
        postChangePasswordPromise(this.form, jwt)
            .then((data) => {
                this.resetForm();
                this.enableSuccessAlert(data.message)
                this.setLoading(false);
            })
            .catch((message) => {
                this.enableErrorAlert(message);
                this.setLoading(false);
            })
    }

    @action
    redirectToFrontPage() {
        window.location.href = '/';
    }

    @action
    enableSuccessAlert(message) {
        this.successMessage = message;
        this.successEnabled = true;
    }

    @action
    enableErrorAlert(message) {
        this.errorMessage = message;
        this.errorEnabled = true;
    }

    @action
    setIsTokenExpired(value) {
        this.isTokenExpired = value;
    }

    @action
    setFormValue(fieldName, value) {
        this.form[fieldName] = value;
        this.successEnabled = false;
        this.errorEnabled = false;
    }

    @action
    setLoading(value) {
        this.loading = value;
    }

    @action
    setInvalidFields(fieldName) {
        this.invalidFields[fieldName] = true;
    }

    @action
    removeInvalidField(fieldName) {
        this.invalidFields[fieldName] = false;
    }

    @action
    resetForm() {
        this.setFormValue('password', '');
        this.setFormValue('repeatPassword', '');
        this.successEnabled = false;
        this.errorEnabled = false;
    }
}

const validateJwtPromise = (jwt) => {
    return new Promise((resolve, reject) => {
        PublicUserApi.validateJwt(jwt)
            .then(() => resolve())
            .catch((res) => {
                if (res.response && res.response.status && res.response.status === 401) {
                    reject('COMMON.PASSWORD_RESET_EXPIRED')
                } else {
                    reject('COMMON.SERVER_ERROR')
                }
            });
    })
}

const postChangePasswordPromise = (body, jwt) => {
    return new Promise((resolve, reject) => {
        UserApi.postPasswordChange(body, jwt)
            .then((res) => resolve(res.data))
            .catch((res) => {
                if (res.response && res.response.data && res.response.data.message) {
                    reject('COMMON.PASSWORD_CHANGE_FAIL')
                } else {
                    reject('COMMON.SERVER_ERROR')
                }
            });
    })
}

export default UserPasswordResetStore;
