import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import LandingTimetable from 'app/modules/landing/components/LandingTimetable';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';

@translate()
@withRouter
@inject('store')
@observer
class PaymentCallback extends Component {
    componentDidMount() {
        this.props.store.payment.callback.getPaymentFromCallback(this.props.location.search);
    }

    getTranslationFromStatus(status) {
        switch (status) {
            case '0':
                return 'COMMON.PAYMENT_CANCELLED';
            case '1':
                return 'COMMON.PAYMENT_SUCCESSFUL';
            case '2':
                return 'COMMON.PAYMENT_ACCEPTED';
            case '3':
                return 'COMMON.PAYMENT_ADITIONAL_INFO';
            default: return '';
        }
    }

    render() {
        const callback = this.props.store['payment'].callback;
        const { t, history } = this.props;
        const { loading, payment, status, errorEnabled, errorMessage } = callback;

        return (
            <div className="position-relative overflow-hidden landing">
                <LandingTimetable />


                <Col xs={11} md={10} lg={8} xl={6} className="mx-auto tab-box pb-0">
                    <div className="user-tab">
                        {loading &&
                        <div className="spinner-box">
                            <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
                        </div>
                        }


                        {!loading && errorEnabled &&
                            <div className="spinner-box p-4">
                                <Alert variant="danger" className="text-center">{t(errorMessage)}</Alert>
                            </div>
                        }

                        {!loading && payment &&
                        <div className="pt-3">
                            <div>
                                <Col md={12} className="text-center mb-3">
                                    <Alert variant={status === '0' ? 'danger' : 'success'} className="text-center">
                                        <span className="font-weight-bolder">
                                            {t(this.getTranslationFromStatus(status))}
                                        </span>
                                    </Alert>
                                </Col>

                                {status !== '0' &&
                                    <Fragment>
                                        <Col md={12} className="text-center mb-3">
                                            <span className="payment-info">
                                                {t('COMMON.PAYMENT_INFO')}
                                            </span>
                                        </Col>

                                        <hr width="100%" />

                                        <Row>
                                            <Col md={12} className="mt-2 mb-2">
                                                <Container>
                                                    <Row>
                                                        <Col md={4} sm={6}>
                                                            <Form.Group>
                                                                <Form.Label>{t('COMMON.ORDER_ID')}</Form.Label>
                                                                <Form.Control value={payment['orderId']} disabled />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4} sm={6}>
                                                            <Form.Group>
                                                                <Form.Label>{t('COMMON.TOTAL_PRICE')}</Form.Label>
                                                                <Form.Control value={
                                                                    Number(payment['amount']).toFixed(2) + ' '
                                                                    + payment['currency']
                                                                } disabled />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4} sm={12}>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    {t('COMMON.SUBSCRIPTION_TITLE')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    value={payment['title']} disabled />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Col>
                                        </Row>

                                        {status === '1' &&
                                            <Fragment>
                                                <hr width="100%" />

                                                <Col md={12} className="text-center mt-3">
                                                    <Alert variant="info" className="text-center">
                                                        <span className="font-weight-bolder">
                                                            {t('COMMON.PAYMENT_INFO_SUBSCRIPTION')}
                                                        </span>
                                                    </Alert>
                                                </Col>

                                                <Col md={12} className="text-center mt-3">
                                                    <Alert variant="info" className="text-center">
                                                <span className="font-weight-bolder">
                                                    {t('COMMON.PAYMENT_INFO_RECEIPT')}
                                                </span>
                                                    </Alert>
                                                </Col>
                                            </Fragment>
                                        }
                                    </Fragment>
                                }
                                <hr width="100%" className="mt-3" />

                                <Col md={12} className="text-center mt-3 mb-3">
                                    <Button variant="primary" size="md" disabled={loading}
                                            onClick={() => history.push('/')}>
                                        {t('COMMON.GO_BACK')}
                                    </Button>
                                </Col>
                            </div>
                        </div>
                        }
                    </div>
                </Col>
            </div>
        );
    }
}

export default PaymentCallback;
