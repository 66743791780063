import axios from 'axios';
import moment from 'moment';

export const API_URL = process.env.REACT_APP_API_URL;

const attachConfig = (config) => {
    return {
        ...config,
    }
};

const emptyStringToNull = (data) => {
    let d = { ...data };

    for (let key in d) {
        if (d.hasOwnProperty(key) && d[key] === '') {
            d[key] = null;
        }
    }

    return d;
};

class Api {
    get(endpoint, config) {
        const q = axios.get(API_URL + endpoint, attachConfig(config));
        q.catch(err => this.handle401(err));
        return q;
    }

    post(endpoint, data, config) {
        const q = axios.post(API_URL + endpoint, emptyStringToNull(data), attachConfig(config));
        q.catch(err => this.handle401(err));
        return q;
    }

    put(endpoint, data, config) {
        const q = axios.put(API_URL + endpoint, emptyStringToNull(data), attachConfig(config));
        q.catch(err => this.handle401(err));
        return q;
    }

    patch(endpoint, data, config) {
        const q = axios.patch(API_URL + endpoint, emptyStringToNull(data), attachConfig(config));
        q.catch(err => this.handle401(err));
        return q;
    }

    delete(endpoint, config) {
        const q = axios.delete(API_URL + endpoint, attachConfig(config));
        q.catch(err => this.handle401(err));
        return q;
    }

    handle401(err) {
        if (axios.isCancel(err)) {
            // Don't do anything if request was cancelled
            return;
        }
        console.error(err);
        // TODO: handle errors, maybe show message?
    }
}

export default new Api();

export const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
};
