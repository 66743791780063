import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import Pagination from 'app/components/pagination/Pagination';
import SubscriptionCell from 'app/components/table/SubscriptionCell';
import SubscriptionOnlineCell from 'app/components/table/SubscriptionOnlineCell';
import DateCell from 'app/components/table/DateCell';
import UserAdminEditModal from 'app/modules/admin/components/Users/UserAdminEditModal';
import UserAdminAddModal from 'app/modules/admin/components/Users/UserAdminAddModal';

@translate()
@inject('store')
@observer
class UsersAdminListTab extends Component {
    render() {
        const { t } = this.props;

        const columns = [
            {
                name: t('COMMON.USER'),
                selector: (row) => {
                    return (<span className="bolder">{row.firstName} {row.lastName}</span>)
                },
                sortable: false,
                minWidth: '195px',
            },
            {
                name: t('COMMON.SUBSCRIPTION'),
                selector: 'subscription',
                format: (row) => {return <SubscriptionCell subscription={row['subscription']} />},
                sortable: true,
                center: true,
                minWidth: '140px',
                maxWidth: '160px',
            },
            {
                name: t('COMMON.ONLINE'),
                selector: 'subscriptionOnline',
                format: (row) => {return <SubscriptionOnlineCell subscription={row['subscriptionOnline']} />},
                sortable: true,
                center: true,
                minWidth: '140px',
                maxWidth: '160px',
            },
            {
                name: t('COMMON.LAST_ACTIVE'),
                selector: 'lastActive',
                format: (row) => {return (<DateCell date={row['lastActive']} format={'yyyy-MM-DD HH:mm'} />)},
                sortable: true,
                center: true,
                minWidth: '140px',
                maxWidth: '160px',
            },
            {
                name: t('COMMON.REGISTERED'),
                selector: 'registrationDate',
                format: (row) => {return (<DateCell date={row['registrationDate']} bold />)},
                sortable: true,
                center: true,
                minWidth: '140px',
                maxWidth: '150px',
            }
        ];

        const { list, edit, add } = this.props.store.userAdmin;

        return (
            <Fragment>
                <Pagination paginatedStore={list} columns={columns} withSearch
                            addBtn addTitle={t('COMMON.ADD')} onAdd={() => add.toggleModal()}
                            pointerOnHover
                            onRowClicked={(row) => edit.onOpen(row.id)} />
                <UserAdminEditModal />
                <UserAdminAddModal />
            </Fragment>
        );
    }
}

export default UsersAdminListTab;
