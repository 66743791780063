import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { configure } from 'mobx';
import rootStore from './store/RootStore';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n/i18n';
import 'assets/fontawesome/css/all.css'

// Enforces MobX to use actions to manipulate state
configure({ enforceActions: 'always' });
ReactDOM.render(
    <Provider store={rootStore}>
        <I18nextProvider i18n={i18n}>
            <React.StrictMode>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </React.StrictMode>
        </I18nextProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
