import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';

@translate()
@inject('store')
@observer
class SubscriptionOnlineCell extends Component {
    isSubscriptionValid() {
        const { subscription } = this.props;
        return moment(subscription).diff(moment().utc(true)) >= 0;
    }

    getSubscriptionDays() {
        const { subscription, withDays } = this.props;

        if (withDays && this.isSubscriptionValid()) {
            return (
                <span className="days-display">
                    {'(' + moment(subscription).diff(moment().startOf('day'), 'days') + ')'}
                </span>
            )
        } else {
            return null;
        }
    }

    render() {
        const { t, subscription } = this.props;

        if (!subscription) {
            return (<span className="sub-not-active">{t('COMMON.SUBSCRIPTION_NOT_VALID')}</span>)
        } else {
            return (
                <span className={`${this.isSubscriptionValid() ? 'sub-active' : 'sub-expired'}`}>
                    {moment(subscription).format('yyyy-MM-DD HH:mm')} {this.getSubscriptionDays()}
                </span>)
        }
    }
}

SubscriptionOnlineCell.propTypes = {
    subscription: PropTypes.any,
    withDays: PropTypes.any,
}

export default SubscriptionOnlineCell;
