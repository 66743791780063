import { Col } from 'react-bootstrap';
import React from 'react';

function LandingDivider() {
    return <Col xs={12} md={{ span: 10, offset: 1 }}>
        <hr className="solid-divider" />
    </Col>
}

export default LandingDivider;
