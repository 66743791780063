import React, { Component } from 'react';
import { Alert, Button, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';
import FormValidators from 'app/components/validators/FormValidators';
import Req from 'app/components/Req';

@translate()
@inject('store')
@observer
class ResetPasswordModalContent extends Component {
    submitForm(e) {
        e.preventDefault();

        const { loginModal } = this.props.store.modal;
        loginModal.postPasswordReset();
    }

    isFormValid() {
        const { form: { email } } = this.props.store.modal.loginModal;
        const { empty } = new FormValidators();

        return !empty(email);
    }

    render() {
        const { t } = this.props;
        const { loginModal } = this.props.store.modal;
        const {
            loginModalPages, errorEnabled, successEnabled, errorMessage, successMessage, loading, form
        } = loginModal;

        const { email } = form;

        const redirectLogin = () => loginModal.setPage(loginModalPages.signIn);
        const valueChange = fieldName => e => loginModal.setFormValue(fieldName, e.target.value);

        return (
            <form autoComplete="off" onSubmit={(e) => this.submitForm(e)}>
                <FormGroup>
                    <FormLabel>{t('COMMON.EMAIL')}<Req /></FormLabel>
                    <FormControl type="email" autoComplete="new-password" value={email}
                                 onChange={valueChange('email')} />
                    <div className="invalid-feedback">{t('COMMON.EMAIL_INVALID')}</div>
                </FormGroup>

                {errorEnabled && <Alert variant="danger" className="text-center">{t(errorMessage)}</Alert>}
                {successEnabled && <Alert variant="success" className="text-center">{t(successMessage)}</Alert>}

                <div className="form-group">
                    <Button variant="primary" className="btn-block" disabled={loading || !this.isFormValid()}
                            type="submit">
                        {t('COMMON.GET_LINK_TO_EMAIL')}
                        {loading && !successEnabled &&
                            <Spinner
                                as="span"
                                animation="border"
                                size="custom"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                    </Button>
                    <Button variant="outline-primary" disabled={loading}
                            className="nav-btn btn-block" onClick={redirectLogin}>
                        {t('COMMON.SIGN_IN')}
                    </Button>
                </div>
            </form>
        );
    }
}

export default ResetPasswordModalContent;
