import WorkoutFormStore from 'store/WorkoutStore/WorkoutFormStore';
import WorkoutAdminListStore from 'store/WorkoutStore/WorkoutAdminListStore';
import WorkoutEditFormModalStore from 'store/WorkoutStore/WorkoutEditFormModalStore';
import WorkoutListStore from 'store/WorkoutStore/WorkoutListStore';
import WorkoutPaidWatch from 'store/WorkoutStore/WorkoutPaidWatch';
import WorkoutFreeWatch from 'store/WorkoutStore/WorkoutFreeWatch';

class WorkoutStore {
    constructor(rootStore) {
        this.form = new WorkoutFormStore(rootStore);
        this.editForm = new WorkoutEditFormModalStore(rootStore);
        this.adminList = new WorkoutAdminListStore(rootStore);
        this.list = new WorkoutListStore(rootStore);
        this.paid = new WorkoutPaidWatch(rootStore);
        this.free = new WorkoutFreeWatch(rootStore);
    }
}

export default WorkoutStore;
