import ProductAdminListStore from 'store/ProductStore/ProductAdminListStore';
import ProductEditStore from 'store/ProductStore/ProductEditStore';

class ProductAdminStore {
    constructor(rootStore) {
        this.list = new ProductAdminListStore(rootStore);
        this.edit = new ProductEditStore(rootStore);
    }
}

export default ProductAdminStore;
