import React, { Component } from 'react';
import { Button, FormControl, FormGroup, FormLabel, Alert, Spinner } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';
import { Link } from 'react-router-dom';
import FormValidators from 'app/components/validators/FormValidators';
import Req from 'app/components/Req';

@translate()
@inject('store')
@observer
class RegisterModalContent extends Component {
    componentDidMount() {
        const { loginModal, loginModal: { form } } = this.props.store.modal;
        const { email, password } = form;

        loginModal.resetInvalidFields();
        loginModal.disableAlerts();

        const { empty, isEmailValid, isPasswordValid } = new FormValidators();

        if (!empty(email) && !isEmailValid(email)) {
            loginModal.setInvalidFields('email');
        }

        if (!empty(password) && !isPasswordValid(password)) {
            loginModal.setInvalidFields('password');
        }
    }

    submitForm(e) {
        e.preventDefault();
        this.props.store.modal.loginModal.postRegistration();
    }

    isFormValid() {
        const { form } = this.props.store.modal.loginModal;
        const { email, password, firstName, lastName, healthTermsAccepted, policyTermsAccepted } = form;

        const { empty, isEmailValid, isPasswordValid } = new FormValidators();

        return isEmailValid(email) && isPasswordValid(password) && !empty(firstName) && !empty(lastName)
            && healthTermsAccepted && policyTermsAccepted;
    }

    checkValidity(fieldName) {
        const { loginModal, loginModal: { form } } = this.props.store.modal;
        const { email, password } = form;

        const { empty, isEmailValid, isPasswordValid } = new FormValidators();

        if (!empty(email) && fieldName === 'email' && !isEmailValid(email)) {
            loginModal.setInvalidFields(fieldName);
        }

        if (!empty(password) && fieldName === 'password' && !isPasswordValid(password)) {
            loginModal.setInvalidFields(fieldName);
        }

        // This needed to trigger rerender
        this.setState({ test: '' });
    }

    valueChange(fieldName, value) {
        const { loginModal } = this.props.store.modal;

        const { isEmailValid, isPasswordValid } = new FormValidators();
        const validate = () => loginModal.removeInvalidField(fieldName);

        if (fieldName === 'email' && isEmailValid(value)) {
            validate();
        }

        if (fieldName === 'password' && isPasswordValid(value)) {
            validate();
        }

        loginModal.setFormValue(fieldName, value);
    }

    render() {
        const { t } = this.props;
        const { loginModal } = this.props.store.modal;
        const {
            loginModalPages, form, invalidFields, errorEnabled, errorMessage, loading, successEnabled, successMessage
        } = loginModal;
        const { email, password, firstName, lastName, healthTermsAccepted, policyTermsAccepted } = form;

        const redirectLogin = () => loginModal.setPage(loginModalPages.signIn);

        const valueChange = fieldName => e => this.valueChange(fieldName, e.target.value)
        const valueChangeCheckbox = fieldName => e => loginModal.setFormValue(fieldName, e.target.checked);

        return (
            <form autoComplete="off" onSubmit={(e) => this.submitForm(e)}>
                <FormGroup>
                    <FormLabel>{t('COMMON.EMAIL')}<Req /></FormLabel>
                    <FormControl type="email" autoComplete="new-password" value={email}
                                 className={`${invalidFields['email'] && 'is-invalid'}`}
                                 onBlur={() => this.checkValidity('email')}
                                 onChange={valueChange('email')} />
                    <div className="invalid-feedback">{t('COMMON.EMAIL_INVALID')}</div>
                </FormGroup>

                <FormGroup>
                    <FormLabel>{t('COMMON.PASSWORD')}<Req /></FormLabel>
                    <FormControl type="password" autoComplete="new-password" value={password}
                                 className={`${invalidFields['password'] && 'is-invalid'}`}
                                 onBlur={() => this.checkValidity('password')}
                                 onChange={valueChange('password')} />
                    <div className="invalid-feedback">{t('COMMON.PASSWORD_TOO_SHORT')}</div>
                </FormGroup>

                <FormGroup>
                    <FormLabel>{t('COMMON.FIRST_NAME')}<Req /></FormLabel>
                    <FormControl type="firstname" value={firstName}
                                 onBlur={() => this.checkValidity('firstName')}
                                 onChange={valueChange('firstName')} />
                </FormGroup>

                <FormGroup>
                    <FormLabel>{t('COMMON.LAST_NAME')}<Req /></FormLabel>
                    <FormControl type="lastname" value={lastName}
                                 onBlur={() => this.checkValidity('lastName')}
                                 onChange={valueChange('lastName')}  />
                </FormGroup>

                <div className="form-group">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="healthCheckbox"
                               checked={healthTermsAccepted}
                               onChange={valueChangeCheckbox('healthTermsAccepted')}
                        />
                        <label className="custom-control-label pointer" htmlFor="healthCheckbox">
                            <span>{t('COMMON.APPROVE_TO')} </span>
                            <Link to="/policy/health" target="_blank">
                                <span className="hyperlink">{t('COMMON.HEALTH_APPROVAL')}</span>
                            </Link>
                        </label>
                    </div>
                </div>

                <div className="form-group">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="privacyCheckbox"
                               checked={policyTermsAccepted}
                               onChange={valueChangeCheckbox('policyTermsAccepted')}
                        />
                        <label className="custom-control-label pointer" htmlFor="privacyCheckbox">
                            <span>{t('COMMON.APPROVE_TO')} </span>
                            <Link to="/policy/privacy" target="_blank">
                                <span className="hyperlink">{t('COMMON.PRIVACY_POLICY')}</span>
                            </Link>
                        </label>
                    </div>
                </div>

                {errorEnabled && <Alert variant="danger" className="text-center">{t(errorMessage)}</Alert>}
                {successEnabled && <Alert variant="success" className="text-center">{t(successMessage)}</Alert>}

                <div className="form-group">
                    <Button variant="primary" className="btn-block" disabled={loading || !this.isFormValid()}
                            type="submit">
                        {t('COMMON.SIGN_UP')}
                        {loading && !successEnabled &&
                            <Spinner
                                as="span"
                                animation="border"
                                size="custom"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                    </Button>
                    <Button variant="outline-primary" className="nav-btn btn-block" disabled={loading}
                            onClick={redirectLogin}>
                        {t('COMMON.SIGN_IN')}
                    </Button>
                </div>
            </form>
        );
    }
}

export default RegisterModalContent;
